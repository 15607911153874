const pending_approval = {
    'transaction.approval.header.pending': '待审批交易',
    'transaction.approval.header.pending.changeToHistory': '查看已审批记录',
    'transaction.approval.header.history': '交易审批记录',
    'transaction.approval.header.pending.changeToHistorPending': '返回待审批交易',
    'transaction.approval.header.pending.createNewTag': '创建新标签',
    'transaction.approval.table.title.userName': '用户名',
    'transaction.approval.table.title.coboID': 'Cobo交易ID',
    'transaction.approval.table.title.OutboundID': 'OutboundID',
    'transaction.approval.table.title.internalTxID': 'InternalTx ID',
    'transaction.approval.table.title.fromAddress': '源地址',
    'transaction.approval.table.title.isDiscussing': '是/否待讨论',
    'transaction.approval.table.title.toAddress': '目标地址',
    'transaction.approval.table.title.coin': '币种',
    'transaction.approval.table.title.amount': '交易金额',
    'transaction.approval.table.title.txFee': '交易费',
    'transaction.approval.table.title.currency': '法币价值',
    'transaction.approval.table.title.time': '交易时间',
    'transaction.approval.table.title.opTime': '审核时间',
    'transaction.approval.table.title.actions': '操作',
    'transaction.approval.table.title.status': '审核状态',
    'transaction.approval.table.title.operator': '审核人',
    'transaction.approval.table.title.memo': '备注信息',
    'transaction.approval.table.title.country': '国家',
    'transaction.approval.table.showTxid': '查看交易ID',
    'transaction.approval.table.showMemo': '查看备注',

    'transaction.approval.search.btn.desc': '搜索',
    'transaction.approval.modal.btn.cancel': '取消',
    'transaction.approval.modal.decline.header': '确定拒绝这笔交易么？',
    'transaction.approval.modal.bulkdecline.header': '确定拒绝这{total}笔交易么？',
    'transaction.approval.modal.decline.header.warning': '用户账户有风险时拒绝',
    'transaction.approval.modal.decline.btn.decline': '拒绝',
    'transaction.approval.modal.approve.btn.apply': '确认',

    'transaction.approval.modal.approve.header': '确定放行这笔交易么？',
    'transaction.approval.modal.bulkapprove.header': '确定放行这{total}笔交易么？',
    'transaction.approval.modal.approve.header.warning': '放行前请确保用户账户安全，此提币申请来自真实用户',
    'transaction.approval.modal.approve.allTransactions':
        '我已与用户确认，他今日还将有数笔类似交易，自动放行此用户今日的所有交易',
    'transaction.approval.modal.approve.btn.approve': '通过',
    'transaction.approval.modal.approve.btn.declined': '拒绝',
    'transaction.approval.modal.approve.btn.showTransaction': '查看',
    'transaction.approval.modal.approve.btn.bulkapprove': '批量通过',
    'transaction.approval.modal.approve.btn.bulkdeclined': '批量拒绝',
};
export default pending_approval;
