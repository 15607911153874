import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown, message } from 'antd';
import { connect } from 'react-redux';
import Global from '@/img/svg/global.svg';

const langMapDesc = {
    zh: '简体中文',
    en: 'English',
};
const languageKey = 'ALADDIN_LANGUAGE';
class LanguageChangeComponentCls extends React.Component {
    componentDidMount() {
        let language = JSON.parse(localStorage.getItem(languageKey) || '{}');
        if (language.locale) {
            this.props.dispatch({
                type: 'SET_LANGUAGE',
                ...language,
            });
        }
    }
    handleMenuClick(e) {
        if (e.key === 'zh') {
            message.info(`切换到${langMapDesc[e.key]}`);
        } else {
            message.info(`Change language to ${langMapDesc[e.key]}`);
        }
        // this.setState({ locale: e.key, locale_desc: langMapDesc[e.key] });
        const language = { locale: e.key, locale_desc: langMapDesc[e.key] };
        localStorage.setItem(languageKey, JSON.stringify(language));
        this.props.dispatch({
            type: 'SET_LANGUAGE',
            ...language,
        });
    }

    render() {
        return (
            <div style={{ marginRight: 32, cursor: 'pointer' }}>
                <Dropdown
                    menu={{
                        items: [
                            { key: 'zh', label: '简体中文' },
                            { key: 'en', label: 'English' },
                        ],
                        onClick: this.handleMenuClick.bind(this),
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <img src={Global} style={{ width: 24, height: 24, marginRight: 8 }} alt='global' />
                        <span>
                            {this.props.locale_desc} <DownOutlined />
                        </span>
                    </div>
                </Dropdown>
            </div>
        );
    }
}
const mapStateToProps = (state) => ({
    locale: state.Language.locale,
    locale_desc: state.Language.locale_desc,
});
let LanguageChangeComponent = connect(mapStateToProps)(LanguageChangeComponentCls);
export default LanguageChangeComponent;
