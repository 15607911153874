const threePartyHostingList = {
    'custody.threePartyHostingList.table.orgName': '客户名称',
    'custody.threePartyHostingList.table.nodeId': 'Node id',
    'custody.threePartyHostingList.table.type': '类型',
    'custody.threePartyHostingList.table.orgId': '客户id',
    'custody.threePartyHostingList.table.nodeName': '托管公司名称',
    'custody.threePartyHostingList.table.status': '状态',
    'custody.threePartyHostingList.table.tripartiteOrgName': '客户托管公司名称',
    'custody.threePartyHostingList.table.coboOrgName': 'cobo开户主体',
    'custody.threePartyHostingList.table.updatedTime': '更新时间',
    'custody.threePartyHostingList.table.createTime': '创建时间',
    'custody.threePartyHostingList.table.updatedBy': '操作人',
    'custody.threePartyHostingList.table.action': '操作',
    'custody.threePartyHostingList.search.all': '全部',
    'custody.threePartyHostingList.search.reject': '拒绝',
    'custody.threePartyHostingList.search.approve': '通过',
    'custody.threePartyHostingList.search.pending': '待审核',
    'custody.threePartyHostingList.search.pendingMatched': '待审核（匹配）',
    'custody.threePartyHostingList.search.pendingUnmatched': '待审核（未匹配）',
    'custody.threePartyHostingList.addNodeid': '新增Node id',
};
export default threePartyHostingList;
