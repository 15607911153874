const mpckyt = {
    'custody.mpckyt.siderBar.title': 'MPC KYT',
    'custody.mpckytDetail.siderBar.title': 'MPC KYT 详情',
    'custody.mpckyt.search.orgnameOrid': 'orgname或org id',
    'custody.mpckyt.search.level': '风险等级',
    'custody.mpckyt.search.category': '类别',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',
    // 'custody.mpckyt.category.status.': '',

    'custody.mpckyt.search.toAddress': '收款地址',
    'custody.mpckyt.search.fromAddress': '打款地址',
    'custody.mpckyt.text.exposureType': '关联关系',
    'custody.mpckyt.text.amount': '金额',
    'custody.mpckyt.text.kycId': 'KYC申请id',
    'custody.mpckyt.text.action': '处理结果',
    'custody.mpckyt.text.coin': '币种',
    'custody.mpckyt.status.freezed': '冻结',
    'custody.mpckyt.status.unfreezed': '解冻',
    'custody.mpckyt.status.pending': '审核中',
    'custody.mpckyt.status.rejected': '审核拒绝',
    'custody.mpckyt.status.apprival': '成功',
};
export default mpckyt;
