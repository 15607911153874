import tssRelay from './tssRelay';
import appManage from './appManage';
import nodeManage from './nodeManage';
import asynqmon from './asynqmon';
import nodeRegister from './nodeRegister';
const tss = {
    ...tssRelay,
    ...nodeManage,
    ...appManage,
    ...nodeRegister,
    ...asynqmon
};

export default tss;
