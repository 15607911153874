const approvalSystem = {
    'approvalSystem.createApprovalProcess': '创建审批流',
    'approvalSystem.approvalProcessDetail': '审批流详情',
    'approvalSystem.approvalManage': '审批管理',
    'approvalSystem.initApproval': '发起审批',
    'approvalSystem.approvalDetail': '审批详情',
    'approvalSystem.createApproval': '创建审批',
    'approvalSystem.approvalCenter': '审批中心',
};
export default approvalSystem;
