const publishUpdateMessage = {
    'coboSettings.publishUpdateMessage.tableTitle.release_notes_zh': 'Update Notes (Chinese)',
    'coboSettings.publishUpdateMessage.tableTitle.release_notes_en': 'Update Notes (English)',
    'coboSettings.publishUpdateMessage.formItem.release_notes_zh': 'Chinese Version',
    'coboSettings.publishUpdateMessage.formItem.release_notes_en': 'English version',
    'coboSettings.publishUpdateMessage.formItem.release_notes_vi': 'Vietnamese version',
    'coboSettings.publishUpdateMessage.formItem.release_notes_es': 'Spanish version',
    'coboSettings.publishUpdateMessage.tableTitle.app_url': 'Download address',
    'coboSettings.publishUpdateMessage.tableTitle.is_force_update': 'Whether to force update',
    'coboSettings.publishUpdateMessage.tableTitle.channels': 'Update channels',
    'coboSettings.publishUpdateMessage.action.success.tips': 'success',
};
export default publishUpdateMessage;
