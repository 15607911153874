const manualInbound = {
    'blockchain.manualInbound': '退款补入账',
    'blockchain.manualInbound.table.head.txid': '原退币交易',
    'blockchain.manualInbound.table.head.orgId': 'Org ID',
    'blockchain.manualInbound.table.head.orgName': '组织名称',
    'blockchain.manualInbound.table.head.Result': '结果',
    'blockchain.manualInbound.table.head.withdraw_txid': '原提币交易',
    'blockchain.manualInbound.formItem.label.refundsAddress': '原退币地址',
    'blockchain.manualInbound.formItem.label.inboundAddress': '入帐地址',
    'blockchain.manualInbound.formItem.label.inboundAddressMemo': '入帐地址的memo',
    'blockchain.manualInbound.formItem.label.amount': '金额',
    'blockchain.manualInbound.formItem.label.phaTaskId': '工单',
    'blockchain.manualInbound.formItem.label.note': '备注',
    'blockchain.manualInbound.formItem.label.chainCoin': '币种',
};
export default manualInbound;
