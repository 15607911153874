const withdrawExploer = {
    'custody.withdrawExploer.searchCondition.Coin': 'Token',
    'custody.withdrawExploer.searchCondition.Status': 'Withdrawal Status',
    'custody.withdrawExploer.searchCondition.mainStatus': 'Withdrawal Status',
    'custody.withdrawExploer.searchCondition.subStatus': 'Withdrawal Sub Status',
    'custody.withdrawExploer.searchCondition.failReason': 'Failed Reason',
    'custody.withdrawExploer.searchCondition.Time': 'Withdrawal Created',
    'custody.withdrawExploer.searchCondition.WithdrawalRequestID': 'Withdrawal Request ID',
    'custody.withdrawExploer.searchCondition.Address': 'To Address',
    'custody.withdrawExploer.searchCondition.TransctionID': 'Transction ID',
    'custody.withdrawExploer.searchCondition.OrgName': 'Org ID/Name',
    'custody.withdrawExploer.search.btn.desc': 'search',

    'custody.withdrawExploer.searchCondition.placeholder.coin': 'Deault All Coins',
    'custody.withdrawExploer.searchCondition.placeholder.WithdrawalRequestID': 'Search by customer request ID',
    'custody.withdrawExploer.searchCondition.placeholder.Address': 'To Address...',
    'custody.withdrawExploer.searchCondition.placeholder.TransctionID': 'Search by Tx ID',
    'custody.withdrawExploer.searchCondition.placeholder.OrgName': 'Deault All',
    'custody.withdrawExploer.searchCondition.placeholder.Status': 'All withdraw transactions...',

    'custody.withdrawExploer.table.header.orgID': 'Org ID',
    'custody.withdrawExploer.table.header.orgName': 'Org Name',
    'custody.withdrawExploer.table.header.toAddress': 'To Address',
    'custody.withdrawExploer.table.header.fromAddress': 'From Address',
    'custody.withdrawExploer.table.header.coin': 'Token',
    'custody.withdrawExploer.table.header.amount': 'Amount',
    'custody.withdrawExploer.table.header.WithdrawalType': 'Withdrawal type',
    'custody.withdrawExploer.table.header.Created': 'Created at',
    'custody.withdrawExploer.table.header.AuditResults': 'Audit Results',
    'custody.withdrawExploer.table.header.AuditBy': 'Audit By',
    'custody.withdrawExploer.table.header.actions': 'Actions',

    'custody.withdrawExploer.actions.note': 'note',
    'custody.withdrawExploer.actions.details': 'detail',
    'custody.withdrawExploer.actions.Approve': 'Approve',
    'custody.withdrawExploer.actions.Reject': 'Reject',
    'custody.withdrawExploer.actions.Terminate': 'Terminate',
    'custody.withdrawExploer.actions.Reload': 'Reload',

    'custody.withdrawExploer.detail.label.Amount': 'Amount',
    'custody.withdrawExploer.detail.label.To': 'To',
    'custody.withdrawExploer.detail.label.RequestID': 'Request ID',
    'custody.withdrawExploer.detail.label.Transaction status': 'Transaction status',
    'custody.withdrawExploer.detail.label.fiatCurrency': 'fiat currency',
    'custody.withdrawExploer.detail.label.Fee': 'Fee',
    'custody.withdrawExploer.detail.label.TxId': 'Tx id',
    'custody.withdrawExploer.detail.label.walletId': 'wallet id',
    'custody.withdrawExploer.detail.label.RiskInformation': 'Risk information',
    'custody.withdrawExploer.detail.label.confirmationsNumber': 'Number of approval confirmations',
    'custody.withdrawExploer.detail.label.successNumber': 'Number of successful withdrawals at current address',
    'custody.withdrawExploer.detail.label.1hourWithdrawals': 'Total withdrawals within 1 hour',
    'custody.withdrawExploer.detail.label.1dayWithdrawals': 'Total withdrawals in 1 day',
    'custody.withdrawExploer.detail.label.authClient': 'Whether auth client',
    'custody.withdrawExploer.detail.label.BindIp': 'Bind ip',
    'custody.withdrawExploer.detail.label.Callback': 'Callback',
    'custody.withdrawExploer.detail.label.AssetInfo': 'Asset information',
    'custody.withdrawExploer.detail.WithdrawalStatus.WithdrawalRequest': 'Withdrawal request',
    'custody.withdrawExploer.detail.WithdrawalStatus.ClientRC': 'Client RC',
    'custody.withdrawExploer.detail.WithdrawalStatus.coboRC': 'cobo RC',
    'custody.withdrawExploer.detail.WithdrawalStatus.BlockchainProcess': 'Blockchain process',
    'custody.withdrawExploer.detail.WithdrawalStatus.custodyProcess': 'Custody process',
    'custody.withdrawExploer.detail.WithdrawalStatus.withdrawalResult': 'withdrawal result',
    'custody.withdrawExploer.detail.WithdrawalStatus.minAgo': 'min ago',
    'custody.withdrawExploer.detail.retryCount': 'Retry Count',
    'custody.withdrawExploer.detail.broadcastMessage':"Broadcast Message",

    'custody.withdrawExploer.actions.error': 'Error',
    'custody.withdrawExploer.actions.success': 'sucess',
    'custody.withdrawExploer.fetchCoins.hints': 'No more coins!',
    // 1. Transaction creation time:
    // 2. Transaction request ID:
    // 3. Quantity:
    // 4. Receiving address:
    'custody.withdrawExploer.modal.note.title':
        'Dear Cobo Custody customer:\nCobo has detected that you have a withdrawal:',
    'custody.withdrawExploer.modal.note.createTime': 'Transaction creation time:',
    'custody.withdrawExploer.modal.note.requestID': 'Transaction request ID:',
    'custody.withdrawExploer.modal.note.number': 'Quantity:',
    'custody.withdrawExploer.modal.note.address': 'Receiving address:',
    'custody.withdrawExploer.modal.note.confirm': 'Please confirm whether it can be released?',
    // 'custody.env.select.lebal': 'Environment',
    // 'custody.env.select.walletProd': 'Wallet Prod',
    // 'custody.env.select.walletSandbox': 'Wallet Sandbox',
    // 'custody.env.select.custodyProd': 'Custody Prod',
    // 'custody.env.select.custodySandbox': 'Custody Sandbox',
    // 'custody.env.select.null': 'Empty',

    // 'custody.explorer.searchCondition.Coin': 'Coin',
    // 'custody.explorer.searchCondition.Status': 'Status',
    // 'custody.explorer.searchCondition.Time': 'Time',
    // 'custody.explorer.search.btn.desc': 'Search',

    // 'custody.explorer.searchCondition.Status.needOfflineCheck': 'Need offline check (-5)',
    // 'custody.explorer.searchCondition.Status.toBeMultiSigned': 'To be multi signed (-4)',
    // 'custody.explorer.searchCondition.Status.toBeApproved': 'To be approved (-3)',
    // 'custody.explorer.searchCondition.Status.newlyGenerated': 'Newly generated (-2)',
    // 'custody.explorer.searchCondition.Status.toBeSigned': 'To be signed (-1)',
    // 'custody.explorer.searchCondition.Status.toBeSent': 'To be sent (0)',
    // 'custody.explorer.searchCondition.Status.alreadySent': 'Already sent (1)',
    // 'custody.explorer.searchCondition.Status.inMemoryPool': 'In memory pool (2)',
    // 'custody.explorer.searchCondition.Status.pending': 'Pending (3)',
    // 'custody.explorer.searchCondition.Status.confirmed': 'Confirmed (4)',
    // 'custody.explorer.searchCondition.Status.failed': 'Failed (5)',

    // 'custody.explorer.searchCondition.Status.internaltx.needApprove': 'Need approval',
    // 'custody.explorer.searchCondition.Status.internaltx.newlyGenerated': 'Newly generated',
    // 'custody.explorer.searchCondition.Status.internaltx.confirmed': 'Confirmed',
    // 'custody.explorer.searchCondition.Status.internaltx.failed': 'Failed',

    // 'custody.withdrawExploer.table.header.canNotRBF': 'Cann`t rbf coin',
    // 'custody.withdrawExploer.table.header.txID': 'TX ID',
    // 'custody.withdrawExploer.table.header.coboID': 'Cobo ID',
    // 'custody.withdrawExploer.table.header.orgID': 'Org ID',
    // 'custody.withdrawExploer.table.header.orgName': 'Org Name',
    // 'custody.withdrawExploer.table.header.walletName': 'Wallet Name',
    // 'custody.withdrawExploer.table.header.orgUserName': 'User',
    // 'custody.withdrawExploer.table.header.userID': 'User ID',
    // 'custody.withdrawExploer.table.header.toAddress': 'To Address',
    // 'custody.withdrawExploer.table.header.coin': 'Coin',
    // 'custody.withdrawExploer.table.header.amount': 'Amount',
    // 'custody.withdrawExploer.table.header.txFee': 'Tx fee',
    // 'custody.detail.modal.label.confirmNumber': 'Confirm Times',
    // 'custody.withdrawExploer.table.header.sendTime': 'Created Time',
    // 'custody.withdrawExploer.table.header.delay': 'Delay time',
    // 'custody.withdrawExploer.table.header.actions': 'Actions',
    // 'custody.withdrawExploer.table.header.actions.retry': 'Retry',
    // 'custody.withdrawExploer.table.header.actions.fail': 'Fail',
    // 'custody.withdrawExploer.table.header.actions.failWarning': 'Fail',
    // 'custody.withdrawExploer.table.body.empty': 'Empty data',

    // 'custody.detail.modal.tab.transactionInfo': 'Transaction',
    // 'custody.detail.modal.tab.transactionOperation': 'Operations',
    // 'custody.detail.modal.tab.transactionOperation.retry': 'Retry records',
    // 'custody.detail.modal.tab.transactionOperation.refuse': 'Refuse records',
    // 'custody.detail.modal.tab.transactionOperation.noRecord': 'No records!',
    // 'custody.detail.modal.label.coboID': 'Cobo ID',
    // 'custody.detail.modal.label.txnID': 'Txn ID',
    // 'custody.detail.modal.label.userID': 'User ID',
    // 'custody.detail.modal.label.amount': 'Amount',
    // 'custody.detail.modal.label.to': 'To',
    // 'custody.detail.modal.label.txnFee': 'Txn Fee',
    // 'custody.detail.modal.label.memo': 'Memo',
    // 'custody.detail.modal.label.gasprice': 'Gas Price',
    // 'custody.detail.modal.label.sendTime': 'Send Time',
    // 'custody.detail.modal.label.createdTime': 'Created Time',
    // 'custody.detail.modal.label.status': 'Status',

    // 'custody.retry.modal.hint.title': 'Are you sure to Fail this transaction?',
    // 'custody.retry.modal.hint.littleTitle': 'The transaction will be canceled and balance back to user',
    // 'custody.retry.modal.return.balance.label': 'Return Balance:',
    // 'custody.retry.modal.return.amount.label': 'Amount',
    // 'custody.retry.modal.return.txfee.label': 'Tx fee',
    // 'custody.retry.modal.return.used.label': 'Used fee',
};
export default withdrawExploer;
