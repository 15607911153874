const superloop = {
    'custody.superloop.siderBar.title': 'Superloop授信结算',
    'custody.superloop.siderBar.detail.title': 'Superloop授信结算详情',
    'custody.superloop.table.header.coin': '授信币种',
    'custody.superloop.table.header.amount': '授信金额',
    'custody.superloop.table.header.fiatCurrency': '法币金额',
    'custody.superloop.table.header.status': '状态',
    'custody.superloop.detail.exchange': '交易所',
    'custody.superloop.detail.frozenWalletName': '冻结钱包名称',
    'custody.superloop.detail.walletType': '钱包类型',
    'custody.superloop.detail.freezeAddress': '冻结地址',

    'custody.superloop.detail.process.userApplication': '用户申请',
    'custody.superloop.detail.process.exchangeProcessing': '交易所确认',
    'custody.superloop.detail.process.exchangeConfirm': '交易所确认中',
    'custody.superloop.detail.process.exchangeRejected': '交易所拒绝',
    'custody.superloop.detail.process.exchangeApproval': '交易所通过',
    'custody.superloop.detail.process.waitinUserAuth': '等待用户auth确认',
    'custody.superloop.detail.process.userAuthApproval': '用户auth确认完成',
    'custody.superloop.detail.process.userMpcSigned': '用户预签名完成(mpc)',
    'custody.superloop.detail.process.failed': '创建授信失败',
    // 'custody.superloop.detail.process.exchangeConfirm': '交易所二次确认中',
    // 'custody.superloop.detail.process.exchangeApproval': '交易所二次确认完成',

    'custody.superloop.detail.process.creditLineCreate': '创建授信',
    'custody.superloop.detail.process.completedCredit': '授信完成',
    'custody.superloop.detail.process.settledCredit': '授信解锁',
    'custody.superloop.detail.process.cancelledCredit': '授信取消',

    'custody.superloop.detail.settlements.createdSettlement': '结算开始',
    'custody.superloop.detail.settlements.processingSettlement': '结算中',
    'custody.superloop.detail.settlements.success': '结算完成',
    'custody.superloop.detail.settlements.failed': '结算失败',
    // 'custody.superloop.detail.settlements.createdSettlement': '结算开始',

    'custody.superloop.table.header.status.init': '初始状态 / 已创建',
    'custody.superloop.table.header.status.AuthProcessing': '用户auth授权中',
    'custody.superloop.table.header.status.AuthUnknownError': '用户auth授权未知错误',
    'custody.superloop.table.header.status.ExchangePreCreateCreditProcessing': '交易所确认中',
    'custody.superloop.table.header.status.ExchangePreCreateCreditUnkownError': '交易所确认未知错误',
    'custody.superloop.table.header.status.LockProcessing': '创建授信中 / 冻结金额中',
    'custody.superloop.table.header.status.LockUnknownError': '创建授信未知错误',
    'custody.superloop.table.header.status.ExchangeConfirmCreateCreditProcessing': '交易所二次确认中',
    'custody.superloop.table.header.status.ExchangeConfirmCreateCreditUnknownError': '交易所二次确认未知错误',
    'custody.superloop.table.header.status.ExchangeCancelCreateCreditProcessing': '交易所取消/回滚中',
    'custody.superloop.table.header.status.ExchangeCancelCreateCreditUnknownError': '交易所取消/回滚未知错误',
    'custody.superloop.table.header.status.CreateCanceledByAuth': '授信取消：用户auth未通过',
    'custody.superloop.table.header.status.CreateCanceledByExchange': '授信取消：交易所错误',
    'custody.superloop.table.header.status.CreateCanceledByLockFail': '授信取消：创建授信失败 / 冻结金额失败',
    'custody.superloop.table.header.status.FinalSettleProcessing': '最终结算中',
    'custody.superloop.table.header.status.FinalSettleUnknownError': '最终结算未知错误',
    'custody.superloop.table.header.status.FinalSettleCompleted': '最终结算完成',

    'custody.superloop.detail.settleStatus.init': '已创建',
    'custody.superloop.detail.settleStatus.Success': '结算成功',
    'custody.superloop.detail.settleStatus.Failed': '结算失败',
    'custody.superloop.detail.settleStatus.PrepareFromCoboProcessing': '转出结算准备中',
    'custody.superloop.detail.settleStatus.PrepareFromCoboProcessingUnknownError': '转出结算准备未知错误',
    'custody.superloop.detail.settleStatus.TransferFromCoboProcessing': '转出结算转账中',
    'custody.superloop.detail.settleStatus.TransferFromCoboProcessingUnknownError': '转出结算转账未知错误',
    'custody.superloop.detail.settleStatus.MonitorToCoboProcessing': '转入结算监控中',
    'custody.superloop.detail.settleStatus.MonitorToCoboUnknownError': '转入结算监控未知错误',
};
export default superloop;
