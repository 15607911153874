import { Layout } from 'antd';
import SiderMenu from './siderMenu';
import RouteConfig from './route';
import TabBar from './tabBar';
import HeaderContent from './header';
import BreadcrumbComponent from './breadcrumb';
import './index.less';
const { Content } = Layout;
function AladdinComponent() {
    return (
        <Layout style={{ height: '100vh' }}>
            <SiderMenu />
            <Layout>
                <HeaderContent />
                <Content
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <TabBar />
                    <BreadcrumbComponent />
                    <div
                        style={{
                            marginTop: 14,
                            flex: 1,
                            overflow: 'auto',
                        }}
                    >
                        <RouteConfig />
                    </div>
                </Content>
            </Layout>
        </Layout>
    );
}

export default AladdinComponent;
