import React from 'react';
import { Link } from 'react-router-dom';
import { Menu, Layout, Skeleton, Tooltip } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { promiseApi } from '@/util/doAction';
import aladdin_host from '@/settings';
import routes from '@/config/menu';
import { useState, useEffect } from 'react';
import Logo from '@/img/svg/logo.svg';
import MenuFold from '@/img/svg/menuFold.svg';
// import { ReactComponent as MenuFold } from '@/img/svg/menuFold.svg';
import MenuUnfold from '@/img/svg/menuUnfold.svg';

const { Sider } = Layout;

function filterBypermission(roots, permission) {
    if (Array.isArray(roots)) {
        roots.forEach((root) => {
            if (root.children) {
                filterBypermission(root.children, permission);
            }
            if (!root.auth || root.auth.find((item) => permission[item])) {
                root.visible = true;
            }
            if (root.children && root.children.filter((item) => item.visible).length) {
                root.visible = true;
            }
        });
    } else {
        if (!roots.auth || roots.auth.find((item) => permission[item])) {
            roots.visible = true;
        }
    }
}
function getItem(label, key, icon, children, type) {
    return {
        key,
        icon,
        children,
        label,
        type,
    };
}

const getMenuItems = (routes) => {
    const items = [];
    if (Array.isArray(routes)) {
        routes.forEach((item) => {
            if (!item.hidden) {
                if (item.children && item.children.length) {
                    items.push(
                        getItem(
                            <>
                                {item.icon}
                                <span>
                                    <FormattedMessage id={item.key}></FormattedMessage>
                                </span>
                            </>,
                            item.path,
                            null,
                            getMenuItems(item.children)
                        )
                    );
                } else if (!item.children) {
                    items.push(
                        getItem(
                            <Tooltip
                                placement='right'
                                trigger='hover'
                                overlayClassName='tooltip-mini'
                                title={<FormattedMessage id={item.key}></FormattedMessage>}
                            >
                                <Link to={item.path}>
                                    <FormattedMessage id={item.key}></FormattedMessage>
                                </Link>
                            </Tooltip>,
                            item.path,
                            null
                        )
                    );
                }
            }
        });
    }
    return items;
};
function getAuthNav(routes) {
    let arr = [];
    routes.forEach((item) => {
        if (item.visible) {
            if (item.children) item.children = getAuthNav(item.children);
            arr.push(item);
        }
    });
    return arr;
}
function SiderMenu(props) {
    const [collapsed, setCollapsed] = useState(false);
    const [loading, setLoading] = useState(false);
    const [openKeys, setOpenKeys] = useState((localStorage.getItem('openKeys') || '').split(','));
    // const { formatMessage }=props
    const [menuRoutes, setMenuRoutes] = useState([]);
    useEffect(() => {
        let permissions = {};
        setLoading(true);
        // 请求菜单权限
        promiseApi(`${aladdin_host}/list_permission_of_self/`, {}, 'GET')
            .then((ret) => {
                if (ret && ret.success) {
                    for (let i in ret.result) {
                        permissions[ret.result[i].permission_name] = true;
                    }
                    props.dispatch({
                        type: 'SET_PERMISSIONS',
                        payload: { permissions },
                    });
                    window.localStorage.setItem('permissions', JSON.stringify(permissions));
                }
                filterBypermission(routes, permissions);
                const authRoute = getAuthNav(routes);
                props.dispatch({
                    type: 'SET_MENU',
                    payload: { menu: authRoute },
                });
                setMenuRoutes(authRoute);
            })
            .finally(() => {
                setLoading(false);
            });
        //eslint-disable-next-line
    }, []);
    const items = getMenuItems(menuRoutes);
    return (
        <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={() => {
                setCollapsed(!collapsed);
            }}
            width={240}
            style={{ overflow: 'auto', background: '#F0F3F7' }}
            trigger={null}
        >
            <div
                style={{
                    height: 56,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '0 24px 0 16px',
                }}
            >
                {!collapsed && <img src={Logo} style={{ width: 95, height: 30 }} alt='logo'></img>}
                <img
                    src={collapsed ? MenuFold : MenuUnfold}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{ width: 18, height: 18 }}
                    alt='menu'
                ></img>
            </div>
            {loading ? (
                <div
                    style={{
                        display: 'flex',
                        padding: 20,
                        height: '100%',
                    }}
                >
                    <Skeleton active />
                </div>
            ) : (
                <Menu
                    style={{ background: '#F0F3F7' }}
                    mode='inline'
                    openKeys={openKeys}
                    // inlineIndent={32}
                    selectedKeys={props.activeKey}
                    items={items}
                    onOpenChange={(e) => {
                        setOpenKeys(e);
                        localStorage.setItem('openKeys', e);
                    }}
                    className='sider-menu'
                >
                    {/* {getSubMenu(menuRoutes)} */}
                </Menu>
            )}
        </Sider>
    );
}
const mapStateToProps = (state) => ({
    menu: state.common.menu,
    activeKey: state.common.activeKey,
});

let HubComponent = connect(mapStateToProps)(injectIntl(SiderMenu));
export default HubComponent;
