const mpcWithdrawExploer = {
    'custody.mpcWithdrawExploer.searchCondition.type': 'Type',
    'custody.mpcWithdrawExploer.select.withdrawalType.Contractcall': 'Contract call',
    'custody.mpcWithdrawExploer.select.withdrawalType.Withdraw': 'Withdraw',
    'custody.mpcWithdrawExploer.detail.label.Protocol': 'Protocol',
    'custody.mpcWithdrawExploer.detail.transaction.status.init': 'init',
    'custody.mpcWithdrawExploer.detail.transaction.status.toBeSent': 'To be sent',
    'custody.mpcWithdrawExploer.detail.transaction.status.Rejected': 'Rejected',
    'custody.mpcWithdrawExploer.detail.transaction.status.checked': 'Pre checked',
    'custody.mpcWithdrawExploer.detail.transaction.status.Queued': 'Queued',
    'custody.mpcWithdrawExploer.detail.transaction.status.Pending': 'Pending',
    'custody.mpcWithdrawExploer.detail.transaction.status.waitSign': 'Wait Sign',
    'custody.mpcWithdrawExploer.detail.transaction.status.Singed': 'Singed',
    'custody.mpcWithdrawExploer.detail.transaction.status.Broadcast': 'Broadcast',
    'custody.mpcWithdrawExploer.detail.transaction.status.BroadcastFail': 'Broadcast Failed',
    'custody.mpcWithdrawExploer.detail.transaction.status.Success': 'Success',
    'custody.mpcWithdrawExploer.detail.transaction.status.Failed': 'Failed',
    'custody.mpcWithdrawExploer.detail.transaction.status.rbf': 'Replace by rbf',
    'custody.mpcWithdrawExploer.detail.transaction.status.Invalid': 'Invalid',
};
export default mpcWithdrawExploer;
