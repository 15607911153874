import { GoogleLogout } from 'react-google-login';
import { FormattedMessage } from 'react-intl';
import { Layout, Popconfirm, Dropdown, Avatar, Tag } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import LanguageChangeComponent from './languageChange';
import { useEffect, useState } from 'react';
import regularApi, { promiseApi } from '@/util/doAction';
import aladdin_host, { client_id, envTag } from '@/settings';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

const { Header } = Layout;

function HeadContent(props) {
    const [userName, setUserName] = useState('');
    useEffect(() => {
        promiseApi(`${aladdin_host}/self_info/`, {}, 'GET').then((data) => {
            if (data.success) {
                setUserName(data.result.name);
                props.dispatch({
                    type: 'SET_USERINFO',
                    payload: { userInfo: data.result },
                });
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleLogout = async (keys) => {
        const data = await regularApi(`${aladdin_host}/logout/`, {}, 'POST');
        if (data && data.success) {
            console.log('Logout');
        }
        window.localStorage.clear();
        window.location.hash = '/login';
    };

    return (
        <Header
            style={{
                background: '#fff',
                display: 'flex',
                justifyContent: 'flex-end',
                height: 56,
                borderBottom: '1px solid #eee',
                alignItems: 'center',
            }}
        >
            <Tag
                color={envTag === '生产环境' ? '#87d068' : 'default'}
                style={{ fontSize: 14, padding: '2px 10px', marginRight: 32 }}
            >
                {envTag}
            </Tag>
            <LanguageChangeComponent />
            <Dropdown
                style={{ cursor: 'pointer' }}
                menu={{
                    items: [
                        {
                            key: 'person',
                            label: <Link to='/aladdin/self'>个人信息</Link>,
                        },
                        {
                            key: 'logout',
                            label: (
                                <GoogleLogout
                                    clientId={client_id}
                                    buttonText='Logout'
                                    render={(renderProps) => (
                                        <Popconfirm
                                            placement='bottom'
                                            title={<FormattedMessage id='aladdin.logout.confirm'></FormattedMessage>}
                                            onConfirm={handleLogout}
                                            okText={<FormattedMessage id='Yes'></FormattedMessage>}
                                            cancelText={<FormattedMessage id='No'></FormattedMessage>}
                                        >
                                            <span>
                                                <FormattedMessage id='aladdin.logout'></FormattedMessage>
                                            </span>
                                        </Popconfirm>
                                    )}
                                    onLogoutSuccess={handleLogout}
                                />
                            ),
                        },
                    ],
                }}
                // overlay={
                //     <Menu onClick={() => {}}>
                //         <Menu.Item key='person'>
                //             <Link to='/aladdin/self'>个人信息</Link>
                //         </Menu.Item>
                //         <Menu.Item key='logout'>
                //             <GoogleLogout
                //                 clientId={client_id}
                //                 buttonText='Logout'
                //                 render={(renderProps) => (
                //                     <Popconfirm
                //                         placement='bottom'
                //                         title={<FormattedMessage id='aladdin.logout.confirm'></FormattedMessage>}
                //                         onConfirm={handleLogout}
                //                         okText={<FormattedMessage id='Yes'></FormattedMessage>}
                //                         cancelText={<FormattedMessage id='No'></FormattedMessage>}
                //                     >
                //                         <span>
                //                             <FormattedMessage id='aladdin.logout'></FormattedMessage>
                //                         </span>
                //                     </Popconfirm>
                //                 )}
                //                 onLogoutSuccess={handleLogout}
                //             />
                //         </Menu.Item>
                //     </Menu>
                // }
            >
                <Link to='/aladdin/self'>
                    <Avatar icon={<UserOutlined />} size={24} />
                    <span style={{ marginLeft: 8, color: '#0F1229', fontSize: 16, fontWeight: 400 }}>{userName}</span>
                </Link>
            </Dropdown>
        </Header>
    );
}
const mapStateToProps = (state) => ({
    userInfo: state.common.userInfo,
});
export default connect(mapStateToProps)(HeadContent);
