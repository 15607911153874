import { Tabs } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
//const { TabPane } = Tabs;

const App = (props) => {
    const history = useHistory();
    const onChange = (activeKey) => {
        const item = props.tabs?.find((item) => item.key === activeKey);

        props.dispatch({
            type: 'SET_ACTIVEKEY',
            payload: { activeKey },
        });
        props.dispatch({
            type: 'SET_TAB',
            payload: { tabs: props.tabs, currentTab: item.Component ? item.key : item.url },
        });

        if (item.url) {
            // fix 组件内跳转
            if (!item.pathname) {
                window.location.href = item.url;
            } else {
                let search = item.url.split('?')[1];
                history.push({
                    ...item,
                    search: search ? `?${search}` : '',
                });
            }
        }
    };
    const items = props.tabs
        .filter((item) => item.key)
        .map(({ key, title, Component, pathname }) => ({
            label: title || <FormattedMessage id={key} />,
            children: Component ? (
                <div style={{ height: '100vh', overflow: 'auto' }}>
                    <Component />
                </div>
            ) : null,
            key: pathname || key,
        }));
    return (
        <Tabs
            tabBarStyle={{ margin: 0 }}
            tabBarGutter={-1}
            rootClassName='menu-tabs-bar'
            hideAdd
            type='editable-card'
            onChange={onChange}
            onEdit={(targetKey, action) => {
                if (action === 'remove') {
                    const panes = props.tabs;
                    const targetIndex = panes.findIndex((pane) => pane.key === targetKey);
                    const newPanes = panes.filter((pane) => pane.key !== targetKey);
                    if (newPanes.length && targetKey === props.currentTab) {
                        const { key } = newPanes[targetIndex === newPanes.length ? targetIndex - 1 : targetIndex];
                        onChange(key);
                    }
                    props.dispatch({
                        type: 'SET_TAB',
                        payload: { tabs: newPanes, currentTab: window.location.href },
                    });
                }
            }}
            activeKey={props.currentTab}
            items={items}
        >
            {/* {props.tabs.map(
                ({ key, title, Component, pathname }) =>
                    key && (
                        <TabPane tab={title || <FormattedMessage id={key} />} key={pathname || key}>
                            {Component && (
                                <div style={{ height: '100vh', overflow: 'auto' }}>
                                    <Component />
                                </div>
                            )}
                        </TabPane>
                    )
            )} */}
        </Tabs>
    );
};
const mapStateToProps = (state) => ({
    tabs: state.common.tabs,
    currentTab: state.common.currentTab,
});

let HubComponent = connect(mapStateToProps)(App);
export default HubComponent;
