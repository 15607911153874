const transaction_explorer = {
    'transaction.env.select.lebal': 'Environment',
    'transaction.env.select.walletProd': 'Wallet Prod',
    'transaction.env.select.walletSandbox': 'Wallet Sandbox',
    'transaction.env.select.custodyProd': 'Custody Prod',
    'transaction.env.select.custodySandbox': 'Custody Sandbox',
    'transaction.env.select.null': 'Empty',

    'transaction.explorer.searchCondition.Coin': 'Coin',
    'transaction.explorer.searchCondition.Status': 'Status',
    'transaction.explorer.searchCondition.Time': 'Time',
    'transaction.explorer.search.btn.desc': 'Search',

    'transaction.explorer.searchCondition.Status.needOfflineCheck': 'Need offline check (-5)',
    'transaction.explorer.searchCondition.Status.toBeMultiSigned': 'To be multi signed (-4)',
    'transaction.explorer.searchCondition.Status.toBeApproved': 'To be approved (-3)',
    'transaction.explorer.searchCondition.Status.newlyGenerated': 'Newly generated (-2)',
    'transaction.explorer.searchCondition.Status.toBeSigned': 'To be signed (-1)',
    'transaction.explorer.searchCondition.Status.toBeSent': 'To be sent (0)',
    'transaction.explorer.searchCondition.Status.alreadySent': 'Already sent (1)',
    'transaction.explorer.searchCondition.Status.inMemoryPool': 'In memory pool (2)',
    'transaction.explorer.searchCondition.Status.pending': 'Pending (3)',
    'transaction.explorer.searchCondition.Status.confirmed': 'Confirmed (4)',
    'transaction.explorer.searchCondition.Status.failed': 'Failed (5)',

    'transaction.explorer.searchCondition.Status.internaltx.needApprove': 'Need approval',
    'transaction.explorer.searchCondition.Status.internaltx.newlyGenerated': 'Newly generated',
    'transaction.explorer.searchCondition.Status.internaltx.confirmed': 'Confirmed',
    'transaction.explorer.searchCondition.Status.internaltx.failed': 'Failed',

    'transaction.explorer.table.header.canNotRBF': 'Cann`t rbf coin',
    'transaction.explorer.table.header.txID': 'TX ID',
    'transaction.explorer.table.header.coboID': 'Cobo ID',
    'transaction.explorer.table.header.orgID': 'Org ID',
    'transaction.explorer.table.header.orgName': 'Org Name',
    'transaction.explorer.table.header.walletName': 'Wallet Name',
    'transaction.explorer.table.header.orgUserName': 'User',
    'transaction.explorer.table.header.userID': 'User ID',
    'transaction.explorer.table.header.toAddress': 'To Address',
    'transaction.explorer.table.header.coin': 'Coin',
    'transaction.explorer.table.header.amount': 'Amount',
    'transaction.explorer.table.header.txFee': 'Tx fee',
    'transaction.detail.modal.label.confirmNumber': 'Confirm Times',
    'transaction.explorer.table.header.sendTime': 'Created Time',
    'transaction.explorer.table.header.delay': 'Delay time',
    'transaction.explorer.table.header.actions': 'Actions',
    'transaction.explorer.table.header.actions.retry': 'Retry',
    'transaction.explorer.table.header.actions.fail': 'Fail',
    'transaction.explorer.table.header.actions.failWarning': 'Fail',
    'transaction.explorer.table.body.empty': 'Empty data',
    'custody.withdrawExploer.detail.label.transactionStatus': 'Transaction Status',

    'transaction.detail.modal.tab.transactionInfo': 'Transaction',
    'transaction.detail.modal.tab.transactionOperation': 'Operations',
    'transaction.detail.modal.tab.transactionOperation.retry': 'Retry records',
    'transaction.detail.modal.tab.transactionOperation.refuse': 'Refuse records',
    'transaction.detail.modal.tab.transactionOperation.noRecord': 'No records!',
    'transaction.detail.modal.label.coboID': 'Cobo ID',
    'transaction.detail.modal.label.txnID': 'Txn ID',
    'transaction.detail.modal.label.userID': 'User ID',
    'transaction.detail.modal.label.amount': 'Amount',
    'transaction.detail.modal.label.to': 'To',
    'transaction.detail.modal.label.txnFee': 'Txn Fee',
    'transaction.detail.modal.label.memo': 'Memo',
    'transaction.detail.modal.label.gasprice': 'Gas Price',
    'transaction.detail.modal.label.sendTime': 'Send Time',
    'transaction.detail.modal.label.createdTime': 'Created Time',
    'transaction.detail.modal.label.status': 'Status',

    'transaction.retry.modal.hint.title': 'Are you sure to Fail this transaction?',
    'transaction.retry.modal.hint.littleTitle': 'The transaction will be canceled and balance back to user',
    'transaction.retry.modal.return.balance.label': 'Return Balance:',
    'transaction.retry.modal.return.amount.label': 'Amount',
    'transaction.retry.modal.return.txfee.label': 'Tx fee',
    'transaction.retry.modal.return.used.label': 'Used fee',
};
export default transaction_explorer;
