const superloopExchange = {
    'custody.superloopExchange.siderBar.title': 'Superloop交易所管理',
    'custody.superloopExchange.siderBar.detail.title': 'Superloop交易所管理详情',

    'custody.superloopExchange.table.header.coin': '授信币种',
    'custody.superloopExchange.table.header.amount': '授信金额',
    'custody.superloopExchange.table.header.fiatCurrency': '法币金额',
    'custody.superloopExchange.table.header.status': '状态',
    'custody.superloopExchange.detail.exchange': '交易所',
    'custody.superloopExchange.detail.frozenWalletName': '冻结钱包名称',
    'custody.superloopExchange.detail.walletType': '钱包类型',
    'custody.superloopExchange.detail.freezeAddress': '冻结地址',

    'custody.superloopExchange.table.header.status.init': '未定义',
    'custody.superloopExchange.table.header.status.Active': '合作中',
    'custody.superloopExchange.table.header.status.Terminated': '已终止',
};
export default superloopExchange;
