const botInstance = {
    'argus.bot_instances': 'Bot Instances',
    'aladdin.argus': 'Argus',
    'argus.bots': 'Argus Bots',
    'argus.safe.address': 'Safe地址',
    'argus.bot.status': 'Bot状态',
    'argus.bot.type': 'Bot类型',
    'argus.bot.type.customized': '自定义', // 3
    'argus.bot.type.farming': '收菜', //4
    'argus.bot.type.withdraw': '撤退', //5
    'argus.bot.type.deleverage': '降杠杆', // 6
    'argus.bot.type.leverage': '升杠杆', // 7
    'argus.all': '全部',
    'argus.bot.running': '运行中', // 1
    'argus.bot.paused': '暂停', // 2
    'argus.bot.terminated': '已取消授权', // 3
    'argus.safe_address': 'Safe地址',
    'argus.safe_name': 'Safe名称',
    'argus.bot_address': 'Bot地址',
    'argus.bot_instance_name': 'Bot实例名称',
    'argus.bot_gas_balance': 'Gas余额',
    'argus.chain': '链',
    'argus.org_name': 'Org名称',
    'argus.bot.instance_id': 'Bot id',
    'argus.bot.triggers': 'Trigger',
    'argus.bot.last_execution_result': '上次执行结果',
    'argus.bot.last_execution_time': '上次执行时间',
    'argus.bot.last_success_execution_time': '上次成功执行时间',
    'argus.bot.last_success_execution_tx': '上次成功执行交易',
    'argus.bot.next_execution_time': '下次执行时间',
    'argus.bot_definition_name': 'Bot定义名称',
    'argus.bot_config': 'Bot配置',
};
export default botInstance;
