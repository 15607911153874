const transaction_explorer = {
    'transaction.env.select.lebal': '交易环境',
    'transaction.env.select.walletProd': 'Wallet生产环境',
    'transaction.env.select.walletSandbox': 'Wallet Sandbox',
    'transaction.env.select.custodyProd': 'Custody生产环境',
    'transaction.env.select.custodySandbox': 'Custody Sandbox',
    'transaction.env.select.null': '未选择',

    'transaction.explorer.searchCondition.Coin': '币种',
    'transaction.explorer.searchCondition.Status': '状态',
    'transaction.explorer.searchCondition.Time': '时间',
    'transaction.explorer.search.btn.desc': '搜索',

    'transaction.explorer.searchCondition.Status.needOfflineCheck': '交易前置审核(-5)',
    'transaction.explorer.searchCondition.Status.toBeMultiSigned': '多签等待中(-4)',
    'transaction.explorer.searchCondition.Status.toBeApproved': '需Signer审核（请报给审核员 -3）',
    'transaction.explorer.searchCondition.Status.newlyGenerated': '交易生成中(-2)',
    'transaction.explorer.searchCondition.Status.toBeSigned': '等待签名(-1)',
    'transaction.explorer.searchCondition.Status.toBeSent': '已签名待发送(0)',
    'transaction.explorer.searchCondition.Status.alreadySent': '已发送至节点(1)',
    'transaction.explorer.searchCondition.Status.inMemoryPool': '等待上链(2)',
    'transaction.explorer.searchCondition.Status.pending': '确认中(3)',
    'transaction.explorer.searchCondition.Status.confirmed': '已确认(4)',
    'transaction.explorer.searchCondition.Status.failed': '失败(5)',

    'transaction.explorer.searchCondition.Status.internaltx.needApprove': '待审核',
    'transaction.explorer.searchCondition.Status.internaltx.newlyGenerated': '交易生成中',
    'transaction.explorer.searchCondition.Status.internaltx.confirmed': '已完成',
    'transaction.explorer.searchCondition.Status.internaltx.failed': '已失败',

    'transaction.explorer.table.header.canNotRBF': '不支持RBF',
    'transaction.explorer.table.header.txID': '交易ID',
    'transaction.explorer.table.header.coboID': 'Cobo ID',
    'transaction.explorer.table.header.orgID': '机构ID',
    'transaction.explorer.table.header.orgName': '机构名称',
    'transaction.explorer.table.header.walletName': '钱包名称',
    'transaction.explorer.table.header.orgUserName': '机构用户',
    'transaction.explorer.table.header.userID': '用户ID',
    'transaction.explorer.table.header.toAddress': '接收地址',
    'transaction.explorer.table.header.coin': '币种',
    'transaction.explorer.table.header.amount': '金额',
    'transaction.explorer.table.header.txFee': '交易费',
    'transaction.explorer.table.header.sendTime': '创建时间',
    'transaction.explorer.table.header.delay': '停滞延时',
    'transaction.explorer.table.header.actions': '操作',
    'transaction.explorer.table.header.actions.retry': '重试',
    'transaction.explorer.table.header.actions.fail': '拒绝',
    'transaction.explorer.table.header.actions.failRisk': '拒绝',
    'transaction.explorer.table.body.empty': '暂无数据',

    'transaction.detail.modal.tab.transactionInfo': '交易信息',
    'transaction.detail.modal.tab.transactionOperation': '操作记录',
    'transaction.detail.modal.tab.transactionOperation.retry': '交易重试记录',
    'transaction.detail.modal.tab.transactionOperation.refuse': '交易拒绝记录',
    'transaction.detail.modal.tab.transactionOperation.noRecord': '暂无记录',
    'transaction.detail.modal.label.coboID': 'Cobo ID',
    'transaction.detail.modal.label.txnID': '交易ID',
    'transaction.detail.modal.label.userID': '用户ID',
    'transaction.detail.modal.label.amount': '交易金额',
    'transaction.detail.modal.label.to': '目标地址',
    'transaction.detail.modal.label.txnFee': '交易费',
    'transaction.detail.modal.label.confirmNumber': '确认数',
    'transaction.detail.modal.label.memo': 'Memo',
    'transaction.detail.modal.label.gasprice': 'Gas Price',
    'transaction.detail.modal.label.sendTime': '发送时间',
    'transaction.detail.modal.label.createdTime': '创建时间',
    'transaction.detail.modal.label.status': '状态',

    'transaction.retry.modal.hint.title': '确定拒绝这笔交易么？',
    'transaction.retry.modal.hint.littleTitle': '交易取消后余额会返还给用户',
    'transaction.retry.modal.return.balance.label': '待返还金额:',
    'transaction.retry.modal.return.amount.label': '交易金额',
    'transaction.retry.modal.return.txfee.label': '交易费',
    'transaction.retry.modal.return.used.label': '应扣除费',
};
export default transaction_explorer;
