const coinManage = {
    'custody.coinManage.siderBar.title': '币种管理',
    'custody.coinManage.table.header.chainCoin': '所属链',
    'custody.coinManage.table.header.fuzzyMatch': '模糊匹配',
    'custody.coinManage.table.header.precision': '精度',
    'custody.coinManage.table.header.minimumDepositThreshold': '最小可入帐金额',
    'custody.coinManage.table.header.visible': '可见范围',
    'custody.coinManage.table.header.displayCode': '展示代码',
    'custody.coinManage.table.header.confirmThreshold': '确认数阈值',
    'custody.coinManage.table.button.edit': '修改可见范围',
};

export default coinManage;
