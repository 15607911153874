const custodyOrganization = {
    'custody.custodyOrganization.custodyUserProduct.old': 'Custody Customer Prod(old)',
    'custody.custodyOrganization.custodyUserProduct': 'Custody Customer Prod',
    'custody.custodyOrganization.custodyUserProduct.detail': 'Custody Customer Prod Details',

    'custody.custodyOrganization.env.sandbox': 'Sandbox',
    'custody.custodyOrganization.env.product': 'Prod',
    'custody.custodyOrganization.createButton.createOrg': 'Create Org',
    'custody.custodyOrganization.search.placeholder': 'Search by Org name',
    'custody.custodyOrganization.table.head.index': 'Index',
    'custody.custodyOrganization.table.head.org': 'Org Name',
    'custody.custodyOrganization.table.head.status': 'Org Status',
    'custody.custodyOrganization.table.head.created_time': 'Created time',
    'custody.custodyOrganization.table.head.asset': 'Asset',
    'custody.custodyOrganization.table.head.configuration': 'Configurations',
    'custody.custodyOrganization.table.head.actions': 'Actions',
    'custody.custodyOrganization.table.body.view': 'View',
    'custody.custodyOrganization.table.body.disable': 'Disable',
    'custody.custodyOrganization.table.body.reinvite': 'Re-invite',
    'custody.custodyOrganization.table.body.enable': 'Enable',
    'custody.custodyOrganization.table.body.status.active': 'Active',
    'custody.custodyOrganization.table.body.status.pending': 'Pending',
    'custody.custodyOrganization.table.body.status.frozen': 'Frozen',
    'custody.custodyOrganization.table.body.status.disabled': 'disabled',
    'custody.custodyOrganization.table.body.cancel': 'Cancel',
    'custody.custodyOrganization.table.body.churnSaving': 'Churn-Saving',
    'custody.custodyOrganization.table.body.stopChurnSaving': 'Stop Churn-Saving',
    'custody.custodyOrganization.table.body.Paid': 'Paid',
    'custody.custodyOrganization.table.body.laterPayment': 'laterPayment',

    'custody.custodyOrganization.table.body.status.available': 'available',
    'custody.custodyOrganization.table.body.sure.disable': 'Are you sure to disable the user?',
    'custody.custodyOrganization.table.body.sure.enable': 'Are you sure to enable the user?',
    'custody.custodyOrganization.table.body.hint.disable':
        'Hint: the action will lock all operation of the org, ensure the org is no longer in use',
    'custody.custodyOrganization.table.body.hint.enable': 'Hint: the action will recover all operation of the org',

    'custody.custodyOrganization.modal.createParams.Organization': 'Organization',
    'custody.custodyOrganization.modal.createParams.AdminUserName': 'Admin User Name',
    'custody.custodyOrganization.modal.createParams.AdminEmail': 'Admin`s Email',
    'custody.custodyOrganization.modal.createParams.AdminMobile': 'Admin`s Mobile',
    'custody.custodyOrganization.modal.createParams.Plan': 'Plan(can adjust details in configurations)',
    'custody.custodyOrganization.modal.createParams.OrgType': 'Org Type',
    'custody.custodyOrganization.modal.createParams.Coins': 'Coins（Optional, separate by ,）',
    'custody.custodyOrganization.modal.createParams.Warn': 'User need to activate account via the link in email above',
    'custody.custodyOrganization.modal.createParams.Invite': 'Invite',
    'custody.custodyOrganization.modal.createParams.useIndependentRemoteWallet': 'separate pools',
    'custody.custodyOrganization.input.useIndependentRemoteWallet.tip':
        'Independent fund pool customers need to contact technical configuration BC risk control related information',
    'custody.custodyOrganization.modal.reserveAccountType.six': '6 Months',
    'custody.custodyOrganization.modal.reserveAccountType.twelve': '12 Months',

    // 添加机构-表单提示
    'custody.custodyOrganization.input.Organization.warn': 'Organization name is required',
    'custody.custodyOrganization.input.AdminUserName.warn': 'Admin name is required',
    'custody.custodyOrganization.input.AdminEmail.warn': 'Email is required',
    'custody.custodyOrganization.input.walletId.warn': 'Separate pool ID is required',

    'custody.custodyOrganization.input.useIndependentRemoteWallet.warn': 'separate pools is required',
    'custody.custodyOrganization.input.AdminUserName.placeholder': 'User name, e.g. Heaven',
    'custody.custodyOrganization.input.AdminEmail.placeholder': 'User`s email(unique ID for login)',
    'custody.custodyOrganization.input.Organization.placeholder': 'English short name, e.g. Cobo',

    'custody.custodyOrganization.modal.configuration.Title': 'Configurations',
    'custody.custodyOrganization.modal.configuration.Plan': 'Packcage',
    'custody.custodyOrganization.modal.configuration.AccountExpiry': 'Account expiry',
    'custody.custodyOrganization.modal.configuration.BasicService': 'Basic Service',
    'custody.custodyOrganization.modal.configuration.parameterDetail': 'parameter details (used/upper limit)',
    'custody.custodyOrganization.modal.configuration.tradingVolume': 'trading volume',
    'custody.custodyOrganization.modal.configuration.SupportedChains': 'Supported Chains (not inc. tokens)',
    'custody.custodyOrganization.modal.configuration.EnterpriseUserAccounts': 'Enterprise User Accounts',
    'custody.custodyOrganization.modal.configuration.ChildWallets': 'Child Wallets',
    'custody.custodyOrganization.modal.configuration.RiskControl': 'Risk Control',
    'custody.custodyOrganization.modal.configuration.SpeedLimits': 'Transaction Speed/Amount Limits',
    'custody.custodyOrganization.modal.configuration.Rebalancing': 'Customizable Account Rebalancing',
    'custody.custodyOrganization.modal.configuration.LowBalanceAlert': 'Low Balance Alert',
    'custody.custodyOrganization.modal.configuration.SystemPerformance': 'System Performance',
    'custody.custodyOrganization.modal.configuration.CallsPerSecond': 'Calls per Second',
    'custody.custodyOrganization.modal.configuration.CallsPerMonth': 'API Calls Limit (per month)',
    'custody.custodyOrganization.modal.configuration.TotalAddress': 'Number of addresses',
    'custody.custodyOrganization.modal.configuration.transactionLimit': 'Monthly transaction limit',
    'custody.custodyOrganization.modal.configuration.bips': 'Overage Charge(Tx. Volume) ',
    'custody.custodyOrganization.modal.configuration.aucBips': 'Overage Charge(AUC) ',
    'custody.custodyOrganization.modal.configuration.Multisig': 'Multisig',
    'custody.custodyOrganization.modal.configuration.MultisigHint':
        'Only multisig user, hide other functions(API, policy)',
    'custody.custodyOrganization.modal.configuration.Coins': 'Coin (separate by , )',
    'custody.custodyOrganization.modal.configuration.DangerCoins': 'Danger Coins(separate by , )',
    'custody.custodyOrganization.modal.configuration.Portfolio': 'Portfolio',
    'custody.custodyOrganization.modal.configuration.CoboSigner': 'Cobo signers',
    'custody.custodyOrganization.modal.configuration.SigNeeded': 'Sig needed',
    'custody.custodyOrganization.modal.configuration.CoboHSM': 'Cobo HSM',
    'custody.custodyOrganization.modal.configuration.CoboCold': 'Cobo Cold',
    'custody.custodyOrganization.modal.configuration.WalletsLimit': 'Wallet Accounts',
    'custody.custodyOrganization.modal.configuration.Others': 'Others （for special use, not normal customer)',
    'custody.custodyOrganization.modal.configuration.OthersHint': 'This is a broker account with credit',
    'custody.custodyOrganization.modal.configuration.TransactionDiscount': 'Transaction fee discount',
    'custody.custodyOrganization.modal.configuration.CoboFeeDiscount': 'Cobo fee discount',
    'custody.custodyOrganization.modal.configuration.CoboManualFeeDiscount': 'Cobo Manual Fee Discount',
    'custody.custodyOrganization.modal.configuration.InnerCoboFeeDiscount': 'Inner fee discount',
    'custody.custodyOrganization.modal.configuration.Remark': 'Remark',
    'custody.custodyOrganization.modal.configuration.businessType': 'Business Type',
    'custody.custodyOrganization.modal.configuration.level': 'level',
    'custody.custodyOrganization.modal.configuration.manager': 'manager',
    'custody.custodyOrganization.modal.configuration.productType': 'Product Type',
    'custody.custodyOrganization.modal.configuration.serviceStartTime': 'service Started Time',
    'custody.custodyOrganization.modal.configuration.serviceEndTime': 'service End Time',
    'custody.custodyOrganization.modal.configuration.TransactionConfiguration': 'Transaction Configuration',
    'custody.custodyOrganization.modal.invite.title': 'Invitation Link',
    'custody.custodyOrganization.modal.invite.hint': 'The link is sent to user, you can also share it.',
    'custody.custodyOrganization.modal.asset.title': 'Total assets',
    'custody.custodyOrganization.modal.configuration.Used': 'Used',
    'custody.custodyOrganization.modal.configuration.overage': 'overage',
    'custody.custodyOrganization.modal.configuration.Reset': 'Reset',
    'custody.custodyOrganization.modal.configuration.churnSavingDate': 'Churn-Saving Date',
    'custody.custodyOrganization.modal.configuration.withdrawalsNumberMonth': 'Number of Withdrawals per Month',
    'custody.custodyOrganization.modal.configuration.setInsurancePackage': 'Set the Insurance Package',
    'custody.custodyOrganization.modal.configuration.suspension': 'Confirm Suspension Due to Arrears',
    'custody.custodyOrganization.modal.configuration.reactivate': 'Confirm to reactivate',
    'custody.custodyOrganization.modal.configuration.normallyPayment': 'Confirm the payment normally?',

    'custody.custodyOrganization.formItem.label.orgVersion': 'Org Version',
    'custody.custodyOrganization.formItem.select.normal_org': 'Custody normal org',
    'custody.custodyOrganization.formItem.select.singapore_org': ' Custody singapore org',

    'custody.custodyOrganization.formItem.label.endTime': 'end Time',
    'custody.custodyOrganization.formItem.label.enabled': 'enabled',
    'custody.custodyOrganization.formItem.label.openingTime': 'account opening time',
    'custody.custodyOrganization.formItem.label.valueAddedServices': 'Add-On Service',
    'custody.custodyOrganization.formItem.label.projectName': 'project name',
    'custody.custodyOrganization.formItem.label.startTime': 'start Time',

    'custody.custodyOrganization.action.button.modifyParameters': 'Modify parameters',
    'custody.custodyOrganization.action.button.revertDefaultConfig': 'Revert to default configuration',

    'custody.custodyOrganization.modal.loop': 'Loop',
    'custody.custodyOrganization.modal.confirm': 'Confirm',
    'custody.custodyOrganization.modal.associatedOrg': 'Associated with an Org',
    'custody.custodyOrganization.modal.notAssociatedOrg': 'Not associated with an Org',
    'custody.custodyOrganization.modal.loop.confirmAssociated':
        'Confirm that the association is currently selected Org',
    'custody.custodyOrganization.modal.loop.confirmRemove': 'Confirm to remove the current associated Org',
    'custody.custodyOrganization.modal.associated': 'associate',
    'custody.custodyOrganization.modal.remove': 'remove',
    'custody.custodyOrganization.modal.endOfMonthCancel': 'end of month cancel',
    'custody.custodyOrganization.modal.configuration.workflow': 'workflow',
    'custody.custodyOrganization.modal.configuration.custodyv2': 'Portal 迁移状态',
    'custody.custodyOrganization.modal.configuration.turnon': 'Turn on',
    'custody.custodyOrganization.modal.configuration.turnoff': 'Turn off',
    'custody.custodyOrganization.tabTitle.fullCustody': 'Full Custody-Send/Receive',
    'custody.custodyOrganization.tabTitle.fullCustodyWeb3': 'Full Custody-Web3',
    'custody.custodyOrganization.tabTitle.coManagedSend': 'Co- managed-Send/Receive',
    'custody.custodyOrganization.tabTitle.coManagedWeb3': 'Co- managed-Web3',
    'custody.custodyOrganization.tabTitle.fullCustodyNew': 'Full Custody-Send/Receive(new)',
    'custody.custodyOrganization.modal.configuration.remoteWalletId': 'Separate Pool ID',
    'custody.custodyOrganization.modal.configuration.remoteWalletId_placeholder': 'Separate Pool ID',
    'custody.custodyOrganization.modal.configuration.isSingaporeOrg': 'singapore compliance org',
    'custody.custodyOrganization.modal.configuration.isSingaporeOrg_true': 'true',
    'custody.custodyOrganization.modal.configuration.isSingaporeOrg_false': 'false',

    'custody.custodyOrganization.button.packagemodification': 'Package modification',
    'custody.custodyOrganization.effectiveTime': 'effective time',
    'custody.custodyOrganization.originalPackage': 'Original package',
    'custody.custodyOrganization.changeTo': 'change to',
    'custody.custodyOrganization.noLimit': 'no limit',
};
export default custodyOrganization;
