const superloop = {
    'custody.superloop.siderBar.title': 'Superloop',
    'custody.superloop.siderBar.detail.title': 'Superloop Detail',
    'custody.superloop.table.header.coin': 'Coin',
    'custody.superloop.table.header.amount': 'Amount',
    'custody.superloop.table.header.fiatCurrency': 'fiat currency',
    'custody.superloop.table.header.status': 'Status',

    'custody.superloop.detail.exchange': 'Exchange',
    'custody.superloop.detail.frozenWalletName': 'Frozen Wallet Name',
    'custody.superloop.detail.walletType': 'Wallet type',
    'custody.superloop.detail.freezeAddress': 'Freeze address',
    'custody.superloop.detail.process.userApplication': 'User Application',
    'custody.superloop.detail.process.exchangeProcessing': 'Exchange Processing',
    'custody.superloop.detail.process.exchangeConfirm': 'Exchange confirming',
    'custody.superloop.detail.process.exchangeRejected': 'Exchange rejected at',
    'custody.superloop.detail.process.exchangeApproval': 'Exchange approval at',
    'custody.superloop.detail.process.waitinUserAuth': 'Waitin user auth',
    'custody.superloop.detail.process.userAuthApproval': 'User auth approval',
    'custody.superloop.detail.process.userMpcSigned': 'user mpc signed',
    'custody.superloop.detail.process.failed': 'Failed',
    'custody.superloop.detail.process.creditLineCreate': 'Credit line create',
    'custody.superloop.detail.process.completedCredit': 'Credit line completed',
    'custody.superloop.detail.process.settledCredit': 'Credit line settled',
    'custody.superloop.detail.process.cancelledCredit': 'Credit line cancelled',
    'custody.superloop.detail.settlements.createdSettlement': 'Settlement created',
    'custody.superloop.detail.settlements.processingSettlement': 'Settlement processing',
    'custody.superloop.detail.settlements.success': 'Settlement success',
    'custody.superloop.detail.settlements.failed': 'Settlement failed',

    'custody.superloop.table.header.status.init': 'init',
    'custody.superloop.table.header.status.AuthProcessing': 'Auth Processing',
    'custody.superloop.table.header.status.AuthUnknownError': 'Auth Unknown Error',
    'custody.superloop.table.header.status.ExchangePreCreateCreditProcessing': 'Exchange Pre Create Credit Processing ',
    'custody.superloop.table.header.status.ExchangePreCreateCreditUnkownError':
        'Exchange Pre Create Credit Unkown Error',
    'custody.superloop.table.header.status.LockProcessing': 'Lock Processing',
    'custody.superloop.table.header.status.LockUnknownError': 'Lock Unknown Error',
    'custody.superloop.table.header.status.ExchangeConfirmCreateCreditProcessing':
        'Exchange Confirm Create Credit Processing',
    'custody.superloop.table.header.status.ExchangeConfirmCreateCreditUnknownError':
        'Exchange Confirm Create Credit Unknown Error',
    'custody.superloop.table.header.status.ExchangeCancelCreateCreditProcessing':
        'Exchange Cancel Create Credit Processing',
    'custody.superloop.table.header.status.ExchangeCancelCreateCreditUnknownError':
        'Exchange Cancel Create Credit Unknown Error',
    'custody.superloop.table.header.status.CreateCanceledByAuth': 'Create Canceled By Auth',
    'custody.superloop.table.header.status.CreateCanceledByExchange': 'Create Canceled By Exchange',
    'custody.superloop.table.header.status.CreateCanceledByLockFail': 'Create Canceled By Lock Fail',
    'custody.superloop.table.header.status.FinalSettleProcessing': 'Final Settle Processing',
    'custody.superloop.table.header.status.FinalSettleUnknownError': 'Final Settle Unknown Error',
    'custody.superloop.table.header.status.FinalSettleCompleted': 'Final Settle Completed',

    'custody.superloop.detail.settleStatus.init': 'created',

    'custody.superloop.detail.settleStatus.Success': 'Success',
    'custody.superloop.detail.settleStatus.Failed': 'Failed',
    'custody.superloop.detail.settleStatus.PrepareFromCoboProcessing': 'Prepare From Cobo Processing',
    'custody.superloop.detail.settleStatus.PrepareFromCoboProcessingUnknownError':
        'Prepare From Cobo Processing Unknown Error',
    'custody.superloop.detail.settleStatus.TransferFromCoboProcessing': 'Transfer From Cobo Processing',
    'custody.superloop.detail.settleStatus.TransferFromCoboProcessingUnknownError':
        'Transfer From Cobo Processing Unknown Error',
    'custody.superloop.detail.settleStatus.MonitorToCoboProcessing': 'Monitor To Cobo Processing',
    'custody.superloop.detail.settleStatus.MonitorToCoboUnknownError': 'Monitor To Cobo Unknown Error',
    // 'custody.superloop.detail.settleStatus.': '',
};
export default superloop;
