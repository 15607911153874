import React from 'react';

import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

import { notification, Modal, Result } from 'antd';

export const openInformation = (title, desc) => {
    notification.open({
        message: title,
        description: desc,
        icon: <InfoCircleOutlined style={{ color: '#1890ff' }} />,
        top: 70,
    });
};

export const openWarning = (title, desc) => {
    notification.open({
        message: title,
        description: desc,
        icon: <MinusCircleOutlined style={{ color: '#faad14' }} />,
        top: 70,
    });
};

export const openSuccess = (title, desc) => {
    notification.open({
        message: title,
        description: desc,
        icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        top: 70,
    });
};

export const openError = (title, desc) => {
    notification.open({
        message: title,
        description: desc,
        icon: <CloseCircleOutlined style={{ color: '#f5222d' }} />,
        top: 70,
    });
};

export const modalSuccess = (title, desc) => {
    let secondsToGo = 5;
    const modal = Modal.info({
        icon: null,
        content: <Result status='success' title={title} subTitle={desc} />,
        centered: true,
    });
    const timer = setInterval(() => {
        secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
    }, secondsToGo * 1000);
};

const Notification = {
    info: openInformation,
    warn: openWarning,
    success: openSuccess,
    error: openError,
    modalSuccess,
};
export default Notification;
