const cobo_wallet_page = {
    'crm.cloud_wallet.userSerachDividerTitle': '用户信息搜索',
    'crm.cloud_wallet.searchConditionUserName': '手机号或邮箱',
    'crm.cloud_wallet.searchConditionUserID': 'ID',
    'crm.cloud_wallet.searchConditionUserAccount': '账号',
    'crm.cloud_wallet.searchConditionUserNationality': '国籍',
    'crm.cloud_wallet.searchConditionUserTags': '标签',
    'crm.cloud_wallet.searchConditionWithFinancialProduct': '是否购买理财',
    'crm.cloud_wallet.searchConditionIsVIP': '是否VIP',
    'crm.cloud_wallet.searchConditionPhone': '手机号',
    'crm.cloud_wallet.searchBtn': '搜索',
    'crm.cloud_wallet.clearBtn': '清空',

    'crm.cloud_wallet.table.header': '用户信息',
    'crm.cloud_wallet.table.head.index1': '用户ID',
    'crm.cloud_wallet.table.head.index2': '用户姓名',
    'crm.cloud_wallet.table.head.index3': '是否可用',
    'crm.cloud_wallet.table.head.index4': '注册时间',
    'crm.cloud_wallet.table.head.index5': '国家',
    'crm.cloud_wallet.table.head.index6': 'KYC',
    'crm.cloud_wallet.table.head.index7': '2FA',
    'crm.cloud_wallet.table.head.index8': '资产',
    'crm.cloud_wallet.table.head.index9': '购买过理财',
    'crm.cloud_wallet.table.head.index10': '标签',
    'crm.cloud_wallet.table.head.index11': '身份证明',
    'crm.cloud_wallet.table.head.paymentpassword': '支付密码',
    'crm.cloud_wallet.table.head.feature_config': '功能白名单',
    'crm.cloud_wallet.table.head.new_devices': '新设备锁定',
    'crm.cloud_wallet.table.head.hodor_change_approval': 'Auth换绑审核',
    'crm.cloud_wallet.view_kyc_change': '用户KYC等级 ',
    'crm.cloud_wallet.change_push': '确认',
    'crm.cloud_wallet.change_cancel': '取消',
    'crm.cloud_wallet.2faUnbind': '确定重置用户2FA ',
    'crm.cloud_wallet.addCC': '增加用户CC余额 ',
    'crm.cloud_wallet.amount.coins_empty': '用户可能尚未添加币种',
    'crm.cloud_wallet.feature_config': '功能白名单',
    'crm.cloud_wallet.securiy_email': '安全邮箱',
    'crm.cloud_wallet.new_devices.unlock': '解除锁定',
    'crm.cloud_wallet.new_devices.unlocked': '已解除',

    'crm.cloud_wallet.tagsTitle': '请输入用户标签',
    'crm.cloud_wallet.identityTitle': '用户基本信息 ',
    'crm.cloud_wallet.identity.picture': '图片信息',
    'crm.cloud_wallet.identity.id': 'ID : ',
    'crm.cloud_wallet.identity.tags': '标签 : ',
    'crm.cloud_wallet.identity.name': '姓名 : ',
    'crm.cloud_wallet.identity.nationality': '国籍 : ',
    'crm.cloud_wallet.identity.account': '账号 : ',
    'crm.cloud_wallet.identity.id_card': '证件号 : ',
    'crm.cloud_wallet.identity.kyc': 'KYC : ',
    'crm.cloud_wallet.identity.member_level_title': '用户会员等级 ',
    'crm.cloud_wallet.identity.member_level': '会员等级 : ',
    'crm.cloud_wallet.identity.btn.upload': '上传信息',
    'crm.cloud_wallet.identity.not_enough_pictures': '暂无图片信息',
    'crm.cloud_wallet.identity.upload_desc': '点击或者拖拽文件至此进行上传',
    'crm.cloud_wallet.amountTitle': '总资产 ',
    'crm.cloud_wallet.CC': 'CC',
    'crm.cloud_wallet.normalCoins': '存币信息',

    'crm.cloud_wallet.feature_config.mortgage': '借贷 Tab',
    'crm.cloud_wallet.feature_config.rewards': '财富 Tab',
    'crm.cloud_wallet.feature_config.triple': '多倍屯币',
    'crm.cloud_wallet.feature_config.bitgrow': '币计划',
    'crm.cloud_wallet.feature_config.staking': 'Staking',
    'crm.cloud_wallet.feature_config.exchange': '闪兑',
    'crm.cloud_wallet.feature_config.etf': 'ETF基金',
    'crm.cloud_wallet.feature_config.dapp': '发现 Tab',
    'crm.cloud_wallet.feature_config.candy': 'Cobo Candy',
    'crm.cloud_wallet.feature_config.enabled': '开启',
    'crm.cloud_wallet.feature_config.disabled': '关闭',

    'crm.cloud_wallet.modalubk': 'Yubikey',
};
export default cobo_wallet_page;
