const common = {
    /**
     * Action 通用操作
     */
    'common.action': 'Action',
    'common.action.View': 'View',
    'common.action.Edit': 'Edit',
    'common.action.Delete': 'Delete',
    'common.action.detail': 'Details',
    'common.action.add': 'Add',
    'common.action.update': 'Update',
    'common.action.upload': 'Upload',
    'common.action.submit': 'Submit',
    'common.action.next': 'Next',
    'common.action.previous': 'Previous',
    'common.action.success.tip': 'Success',
    'common.action.error.tip': 'Error',
    'common.action.refresh': 'Refresh',
    'common.action.search': 'Search',
    'common.action.reset': 'Reset',
    'common.action.save': 'Save',
    'common.action.history': 'History',
    'common.action.cancel': 'cancel',
    'common.action.export': 'Export',

    /**
     * Table 通用文案
     */
    'common.table.colomnTitle.index': 'Index',
    'common.table.colomnTitle.name': 'Name',
    'common.table.colomnTitle.version': 'Version',
    'common.table.colomnTitle.updateTime': 'update Time',
    'common.table.colomnTitle.updatedBy': 'Updated By',
    'common.table.colomnTitle.createTime': 'Create Time',
    'common.table.colomnTitle.createdBy': 'Created By',
    'common.table.colomnTitle.status': 'Status',
    'common.table.colomnTitle.approvedBy': 'Approved By',
    'common.table.colomnTitle.note': 'Version Note',
    'common.table.colomnTitle.action': 'Action',

    /**
     * 审批状态 通用文案
     */
    'common.text.approvalStatus': 'Approval Status',
    'common.text.approvalStatus.pendingApproval': 'Pending Approval',
    'common.text.approvalStatus.rejeced': 'rejectd',
    'common.text.approvalStatus.approved': 'approved',
    'common.text.approvalStatus.grayscaleRejeced': 'Grayscale Rejeced',
    'common.text.approvalStatus.grayscaleApproved': 'Grayscale Approved',

    /**
     * 上线状态 通用文案
     */
    'common.text.onlineStatus': 'Online Status',
    'common.text.onlineStatus.notOnline': 'Not Online',
    'common.text.onlineStatus.online': 'Online',
    'common.text.onlineStatus.offline': 'Offline',
    'common.text.onlineStatus.grayscaleOnline': 'Grayscale Online',
    'common.text.onlineStatus.grayscaleNotOnline': 'Grayscale Not Online',
    'common.text.onlineStatus.grayscaleError': 'Grayscale Error',

    /**
     * 创建类型 通用文案
     */
    'common.text.createType': 'Create Type',
    'common.text.createType.update': 'UPDATE',
    'common.text.createType.create': 'CTEATE',

    /**
     * Text 通用文案
     */
    'common.text.version': 'Version',
    'common.text.Yes': 'Yes',
    'common.text.No': 'No',
    'common.text.all': 'All',
    'common.text.remark': 'Remark',

    /**
     * 表单提示
     */
    'common.formInput.message.required': 'Please Input ',

    /**
     * Table org 通用文案
     */
    'common.table.colomnTitle.orgID': 'Org Id',
    'common.table.colomnTitle.orgName': 'Org Name',

    /**
     * Table 币种 通用文案
     */
    'common.table.colomnTitle.coin': 'Coin',
    'common.table.colomnTitle.amount': 'Amount',
    'common.table.colomnTitle.fiatCurrency': 'fiat currency',

};
export default common;
