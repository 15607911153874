const publishUpdateMessage = {
    'coboSettings.publishUpdateMessage.tableTitle.release_notes_zh': '更新说明(中文)',
    'coboSettings.publishUpdateMessage.tableTitle.release_notes_en': '更新说明(英文)',
    'coboSettings.publishUpdateMessage.formItem.release_notes_zh': '中文版',
    'coboSettings.publishUpdateMessage.formItem.release_notes_en': '英文版',
    'coboSettings.publishUpdateMessage.formItem.release_notes_vi': '越南版',
    'coboSettings.publishUpdateMessage.formItem.release_notes_es': '西班牙版',
    'coboSettings.publishUpdateMessage.tableTitle.app_url': '下载地址',
    'coboSettings.publishUpdateMessage.tableTitle.is_force_update': '是否强制更新',
    'coboSettings.publishUpdateMessage.tableTitle.channels': '更新渠道',
    'coboSettings.publishUpdateMessage.action.success.tips': '操作成功',
};
export default publishUpdateMessage;
