import { Layout, Row, Col } from 'antd';
import { connect } from 'react-redux';
import Notification from './notification';
// import { GoogleLogin } from 'react-google-login';
import { GoogleLogin as GoogleLoginOAuth, GoogleOAuthProvider } from '@react-oauth/google';
import { client_id } from '../settings';
import AladdinDashboard from '@/img/ala.png';
import AladdinLogo from '@/img/logo.png';
import { aladdin_host_x } from '../settings';
import regularApi from '../util/doAction';
let aladdin_host = aladdin_host_x;

const { Content } = Layout;
async function handleFinishGoogleAuth(keys) {
    console.log(keys);
    window.localStorage.setItem('aladdin_session', keys.credential);
    const ret = await regularApi(`${aladdin_host}/google_login/`, {}, 'POST');
    if (ret.success) {
        window.location.href = window.location.origin;
    } else {
        Notification.warn(
            'Error',
            ret.error_message && ret.error_message !== '' ? `${ret.error_message}` : `${ret.result.error_message}`
        );
    }
}
async function handleFailure(keys) {
    Notification.warn(this.props.locale === 'en' ? 'Error' : '登陆失败，请稍后重试', '');
}

function GoogleLoginComponent() {
    window.localStorage.removeItem('aladdin_session');
    window.localStorage.removeItem('permissions');
    return (
        <div>
            <Layout>
                <Layout className='aladdin-login-layout'>
                    <Content
                        className='with-center'
                        style={{
                            minWidth: '100%',
                            maxWidth: '1000px',
                            height: '100vh',
                            backgroundColor: '#EBEFF1',
                        }}
                    >
                        {/* <img width={900} src={Aladdin_dashboard} alt=''></img> */}
                        <Row justify='space-around' align='middle' style={{ height: '100%' }}>
                            <Col>
                                <div className='aladdin-login-box'>
                                    <div className='aladdin-login-box-item-left'>
                                        <div
                                            className='aladdin-login-box-item-left-logo'
                                            style={{ backgroundImage: `url(${AladdinLogo})` }}
                                        ></div>
                                        <div className='aladdin-login-box-item-left-aladdin-zh'>
                                            <span>阿拉丁运营后台</span>
                                        </div>
                                        <div className='aladdin-login-box-item-left-aladdin-en'>
                                            <span>User Operation System</span>
                                        </div>
                                        <div className='aladdin-login-box-item-left-aladdin-btn'>
                                            <GoogleOAuthProvider clientId={client_id}>
                                                <GoogleLoginOAuth
                                                    className='aladdin-google-auth-login'
                                                    text='continue_with'
                                                    size='large'
                                                    auto_select
                                                    onSuccess={handleFinishGoogleAuth}
                                                    onError={handleFailure}
                                                    theme='filled_blue'
                                                    width={300}
                                                ></GoogleLoginOAuth>
                                            </GoogleOAuthProvider>
                                        </div>
                                    </div>
                                    <div
                                        className='aladdin-login-box-item-right'
                                        style={{ backgroundImage: `url(${AladdinDashboard})` }}
                                    ></div>
                                </div>
                            </Col>
                        </Row>
                    </Content>
                </Layout>
            </Layout>
        </div>
    );
}

const mapStateToProps = (state) => ({
    locale: state.Language.locale,
    locale_desc: state.Language.locale_desc,
});

let GoogleLoginComponentObj = connect(mapStateToProps)(GoogleLoginComponent);
export default GoogleLoginComponentObj;
