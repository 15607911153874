const travelRuleInboundRefund = {
    'custody.travelRuleInboundRefund.title': 'Travel Rule Inbound Refund',
    'custody.travelRuleInboundRefund.siderBar.title': 'Travel Rule Inbound Refund',

    'custody.travelRuleInboundRefund.label.txid': 'TxID',
    'custody.travelRuleInboundRefund.label.txaddrfrom': 'From',
    'custody.travelRuleInboundRefund.label.txaddrto': 'To',
    'custody.travelRuleInboundRefund.label.orgidorname': 'Org Name/ID',
    'custody.travelRuleInboundRefund.label.coin': 'Coin',
    'custody.travelRuleInboundRefund.label.status': 'Status',

    'custody.travelRuleInboundRefund.placeholder.txid': 'TxID',
    'custody.travelRuleInboundRefund.placeholder.txaddrfrom': 'From',
    'custody.travelRuleInboundRefund.placeholder.txaddrto': 'To',
    'custody.travelRuleInboundRefund.placeholder.orgidorname': 'Org Name/ID',
    'custody.travelRuleInboundRefund.placeholder.coin': 'Coin',
    'custody.travelRuleInboundRefund.placeholder.status': 'Status',

    'custody.travelRuleInboundRefund.searchBtn.desc': 'Search',
    'custody.travelRuleInboundRefund.resetBtn.desc': 'Reset',

    'custody.travelRuleInboundRefund.table.org_id': 'Org ID',
    'custody.travelRuleInboundRefund.table.org_name': 'Org Name',
    'custody.travelRuleInboundRefund.table.coin': 'Coin',
    'custody.travelRuleInboundRefund.table.amount': 'Amount',
    'custody.travelRuleInboundRefund.table.timestamp': 'Time',
    'custody.travelRuleInboundRefund.table.txid': 'TxID',
    'custody.travelRuleInboundRefund.table.txaddrfrom': 'From',
    'custody.travelRuleInboundRefund.table.txaddrto': 'To',
    'custody.travelRuleInboundRefund.table.status': 'Status',
    'custody.travelRuleInboundRefund.table.actions': 'Actions',

    'custody.travelRuleInboundRefund.table.actions.refund': 'Refund',
    'custody.travelRuleInboundRefund.confirm.refund.title': 'Refunding Inbound',
    'custody.travelRuleInboundRefund.confirm.refund.desc': 'Are you absolutely positive to refund this?',
    'custody.travelRuleInboundRefund.confirm.refund.cancelled': 'Refund Cancelled',
    'custody.travelRuleInboundRefund.table.actions.pass': 'Force Pass',
    'custody.travelRuleInboundRefund.confirm.pass.title': 'Forcing Pass Travel Rule',
    'custody.travelRuleInboundRefund.confirm.pass.desc': 'Are you absolutely positive to force pass travel rule?',
    'custody.travelRuleInboundRefund.confirm.pass.cancelled': 'Force Pass Cancelled',
    'custody.travelRuleInboundRefund.table.actions.refund.message': 'Please proceed with your Cobo Guard',
    'custody.travelRuleInboundRefund.table.actions.pass.message': 'Please proceed with your Cobo Guard',
    'custody.travelRuleInboundRefund.table.actions.not-operable.message':
        'This record is not currently operable: {reason}',
    'custody.travelRuleInboundRefund.table.actions.not-operable.default-reason': 'Unspecified Reason',
};
export default travelRuleInboundRefund;
