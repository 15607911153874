const suspendedManagement = {
    'custody.suspendedManagement.tableTitle.coin': 'coin',
    'custody.suspendedManagement.tableTitle.recvSuspended': 'Deposit status',
    'custody.suspendedManagement.tableTitle.sendSuspended': 'Withdrawal status',
    'custody.suspendedManagement.FormItem.suspendedRecv': 'Suspend deposit',
    'custody.suspendedManagement.FormItem.suspendedSend': 'Suspend withdrawals',
    'custody.suspendedManagement.FormItem.subSuspendedSend': 'Sub-coins take effect at the same time',
    'custody.suspendedManagement.Button.suspendedSend': 'Suspend deposit and withdrawal',
    'custody.suspendedManagement.modalTitle.suspendedSend': 'Modify deposit and withdrawal information',
    'custody.suspendedManagement.suspended.close.status': 'Close',
    'custody.suspendedManagement.suspended.open.status': 'Open',
};
export default suspendedManagement;
