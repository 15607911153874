const threePartyHostingList = {
    'custody.threePartyHostingList.table.orgName': 'Org name',
    'custody.threePartyHostingList.table.nodeId': 'Node id',
    'custody.threePartyHostingList.table.type': 'Type',
    'custody.threePartyHostingList.table.orgId': 'Org ID',
    'custody.threePartyHostingList.table.nodeName': 'Node name',
    'custody.threePartyHostingList.table.status': 'Status',
    'custody.threePartyHostingList.table.tripartiteOrgName': 'Tripartite Org name',
    'custody.threePartyHostingList.table.coboOrgName': 'cobo org name',
    'custody.threePartyHostingList.table.updatedTime': 'Updated time',
    'custody.threePartyHostingList.table.createTime': 'Created time',
    'custody.threePartyHostingList.table.updatedBy': 'Updated by',
    'custody.threePartyHostingList.table.action': 'Action',
    'custody.threePartyHostingList.search.pending': 'Pending',
    'custody.threePartyHostingList.search.pendingMatched': 'Pending-Mathched',
    'custody.threePartyHostingList.search.pendingUnmatched': 'Pending-UnMathched',
    'custody.threePartyHostingList.search.reject': 'Reject',
    'custody.threePartyHostingList.search.all': 'All',
    'custody.threePartyHostingList.search.approve': 'Approve',
    'custody.threePartyHostingList.addNodeid': 'Add New node Id',
};
export default threePartyHostingList;
