const adminTeamUser = {
    'admin.team.tableHeader.Index': '用户ID',
    'admin.team.tableHeader.Name': '姓名',
    'admin.team.tableHeader.department': '部门',
    'admin.team.tableHeader.firstName': '名',
    'admin.team.tableHeader.lastName': '姓',
    'admin.team.tableHeader.email': '员工邮箱',
    'admin.team.tableHeader.supervisor': '直属上级',
    'admin.team.tableHeader.firstDepartmentSupervisor': '一级部门上级',
    'admin.team.tableHeader.Section': '权限',
    'admin.team.tableHeader.Action': '操作',
    'admin.team.tableHeader.action.section': '权限',
    'admin.team.tableHeader.2FAStatus': '2FA 状态',
    'admin.team.tableHeader.UBKStatus': 'UBK 状态',
    'admin.team.tableHeader.HodorStatus': 'Auth 状态',
    'admin.team.tableHeader.Status': '用户状态',
    'admin.team.tableHeader.action.log': '日志',
    'admin.team.tableHeader.action.bind_ubk': '添加UBK',
    'admin.team.tableHeader.action.bind_hodor': '添加Auth',
    'admin.team.tableHeader.action.bind_hodor_search': '查看绑定状态',
    'admin.team.tableHeader.action.reset_passwrod': '重置密码',
    'admin.team.tableHeader.action.reset_2fa': '重置2FA',
    'admin.team.tableHeader.action.invite_again': '重新邀请',
    'admin.team.tableHeader.action.unfreeze': '解封',
    'admin.team.tableHeader.action.freeze': '冻结',
    'admin.team.tableHeader.action.reset_ubk': '重新绑定UBK',
    'admin.team.tableHeader.action.reset_hodor': '重新绑定Auth',
    'admin.team.tableHeader.action.show_qr': '查看二维码',

    'admin.team.inviteUser.title': '添加用户',
    'admin.team.inviteUser.refreshPermission': '刷新权限',
    'admin.team.inviteUser.name': '用户名',
    'admin.team.inviteUser.email': '邮箱',
    'admin.team.inviteUser.mobile': '手机号',
    'admin.team.email.Invalid': '无效邮箱',
    'admin.team.name.Invalid': '用户名不能为空',
    'admin.team.mobile.Invalid': '无效手机号',

    'admin.team.table.userStatus.created': '已创建',
    'admin.team.table.userStatus.waiting_2fa': '待绑定2fa',
    'admin.team.table.userStatus.active': '可用',
    'admin.team.table.userStatus.deactive': '不可用',
    'admin.team.table.userStatus.freeze': '已冻结',

    'admin.team.table.2fa.none': '无',
    'admin.team.table.2fa.binding': '待绑定',
    'admin.team.table.2fa.bound': '已绑定',

    'admin.team.userView.name': '用户名',
    'admin.team.userView.email': '邮箱',
    'admin.team.userView.status': '状态',

    'admin.team.userView.mobile': '手机号',
    'admin.team.userView.resetBtn': '重置用户',
    'admin.team.confirm.resetUser.title': '确定要重置当前用户',

    'admin.team.confirm.hodorReset.title': '确认信息',
    'admin.team.confirm.hodorReset.Modal.content': '确认后已绑定信息将失效',
    'admin.team.confirm.freezeUser.Modal.content': '请确认是否冻结用户 ',
    'admin.team.confirm.unfreezeUser.Modal.content': '请确认是否解冻用户 ',

    'admin.team.queryLoop.error': '自动查询失败',
    'admin.team.queryLoop.sucess': '绑定成功',

    'admin.team.freezeUser.sucess': '操作成功',
    'admin.team.unfreezeUser.sucessCue': '用户已冻结',
    'admin.team.freezeUser.sucessCue': '用户已冻结',

    'admin.team.BindUBK.title': '绑定 Yubikey',
    'admin.team.BindUBK.cid': 'Yubikey CID',
    'admin.team.BindUBK.sk': 'Yubikey SK',
    'admin.team.BindHodor.title': '绑定 Auth',
    'admin.team.BindHodor.pubkey': 'Auth pubkey',
    'admin.team.BindUBK.ubkid': 'Yubikey ID',
};
export default adminTeamUser;
