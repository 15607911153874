const riskControlRuleManagement = {
    /**
     * 左侧菜单
     */
    'riskControl.ruleManagement': '风控规则管理',
    'riskControl.rulesDetail': '风控规则详情',
    'riskControl.rulesUpload': '风控规则上传',
    'riskControl.rulesVersions': '风控规则历史版本',

    /**
     * 上线模式 通用文案
     */
    'riskControl.text.onlineMode': '上线模式',
    'riskControl.text.onlineMode.approvalOnline': '正常上线',
    'riskControl.text.onlineMode.emergencyOnline': '紧急上线',

    /**
     * 组件：BPMN文件展示集成卡片组件
     */
    'riskControl.bpmn.bpmnIntegratedCard.diffPreview': '预览区别',
    'riskControl.bpmn.bpmnIntegratedCard.format': 'BPMN视图',
    'riskControl.bpmn.bpmnIntegratedCard.display': '展示',
    'riskControl.bpmn.bpmnIntegratedCard.nodeProperties.title': '选中节点包含的属性如下',

    /**
     * 规则列表页面
     */
    // tab
    'riskControl.ruleList.tab.title.online': '线上',
    'riskControl.ruleList.tab.title.pendingReview': '待审核',
    'riskControl.ruleList.tab.button.AddBpmn': '创建 BPMN 规则',
    'riskControl.ruleList.tab.button.AddBusinessDefinition': '创建 Business Definition',
    // table
    'riskControl.ruleList.table.colomnTitle.index': '编号',
    'riskControl.ruleList.table.colomnTitle.name': '名称',
    'riskControl.ruleList.table.colomnTitle.version': '版本',
    'riskControl.ruleList.table.colomnTitle.updateTime': '更新时间',
    'riskControl.ruleList.table.colomnTitle.updatedBy': '更新人',
    'riskControl.ruleList.table.colomnTitle.createTime': '创建时间',
    'riskControl.ruleList.table.colomnTitle.action': '操作',
    // action links
    'riskControl.ruleList.table.actionLink.versionHistory': '版本历史',

    /**
     * 上传规则两步骤页面
     */
    // 步骤条 steps
    'riskControl.bpmnUpload.steps.one': '选择 BPMN 文件',
    'riskControl.bpmnUpload.steps.two': '上传文件确认',
    // 步骤一
    'riskControl.bpmnUpload.stepsOne.form.fileName': '文件名',
    'riskControl.bpmnUpload.stepsOne.form.chooseFile': '选择文件',
    'riskControl.bpmnUpload.stepsOne.form.clickChoose': '点击选择',
    'riskControl.bpmnUpload.stepsOne.form.errorMessage.fileNotChoose': '请选择 BPMN 文件',
    'riskControl.bpmnUpload.businessDefinitionIdList': '关联 Process Definition 列表',
    'riskControl.bpmnUpload.businessDefinitionIdList.tip':
        '请注意更新该BMPN文件会影响所有关联的 Process Definition 列表',

    // 步骤二
    'riskControl.bpmnUpload.stepsTwo.bpmnFile': 'BPMN文件',
    'riskControl.bpmnUpload.stepsTwo.action.dataTest': '数据测试',
    'riskControl.bpmnUpload.stepsTwo.selectOption.BpmnDiagram': 'BPMN视图',
    'riskControl.bpmnUpload.stepsTwo.selectLabel.remark': '备注',

    /**
     * 规则详情页面
     */
    // 请求提示
    'riskControl.bpmnDetail.request.approval.official.comfirm': '请确认是否提交审核通过！',
    'riskControl.bpmnDetail.request.approval.official.success': '提交审核通过！',
    'riskControl.bpmnDetail.request.approval.gray.success': '提交灰度审核通过！',
    'riskControl.bpmnDetail.request.rejected.comfirm': '请确认是否拒绝该 BPMN 版本',
    'riskControl.bpmnDetail.request.rejected.success': '成功拒绝该 BPMN 版本！',
    'riskControl.bpmnDetail.request.revert.comfirm': '请确认是需要回滚到该 BPMN 版本吗？',
    'riskControl.bpmnDetail.request.revert.success': '成功回滚该 BPMN 版本！',
    'riskControl.bpmnDetail.request.releaseAllFromGray.comfirm': '请确认是需要全量发布该 BPMN 版本吗？',
    'riskControl.bpmnDetail.request.releaseAllFromGray.success': '成功全量发布该 BPMN 版本！',
    'riskControl.bpmnDetail.request.stopGray.comfirm': '请确认是需要停止该 BPMN 版本灰度吗？',
    'riskControl.bpmnDetail.request.stopGray.success': '成功停止该 BPMN 版本灰度功能！',
    'riskControl.bpmnDetail.request.enableGray.comfirm': '请确认是需要重新启用该 BPMN 版本灰度吗？',
    'riskControl.bpmnDetail.request.enableGray.success': '成功启用该 BPMN 版本灰度功能！',
    // 页头详情取
    'riskControl.bpmnDetail.pageHeader.subtitle': '详情属性如下',
    // action
    'riskControl.bpmnDetail.action.agree': '同意',
    'riskControl.bpmnDetail.action.reject': '拒绝',
    'riskControl.bpmnDetail.action.download': '下载',
    'riskControl.bpmnDetail.action.dataTest': '数据测试(内测阶段)',
    'riskControl.bpmnDetail.action.revert': '还原此版本',
    'riskControl.bpmnDetail.action.officialRelease': '全量发布',
    'riskControl.bpmnDetail.action.stopGrayForNormal': '灰度正常终止',
    'riskControl.bpmnDetail.action.stopGrayForAbnormal': '灰度异常终止',
    'riskControl.bpmnDetail.action.manageGrayList': '灰度名单管理',
    'riskControl.bpmnDetail.action.enableGray': '启用灰度',
    // “配置管理灰度名单” Modal
    'riskControl.bpmnDetail.grayListConfigDialog.grayListLabel': '灰度名单',
    'riskControl.bpmnDetail.grayListConfigDialog.textarea.placeholder':
        '用“;”来分割不同org_id的配置；用“@”来分割org_id和user_id直接分割；用“,”来分别user_id之间；如22@123,345,567;34@3343;67',
    'riskControl.bpmnDetail.grayListConfigDialog.title': '编辑灰度名单',
    'riskControl.bpmnDetail.grayListConfigDialog.request.success': '成功编辑灰度名单！',
    // “审核通过信息确认” Modal
    'riskControl.bpmnDetail.agreeModal.title': '审核通过信息确认',
    'riskControl.bpmnDetail.agreeModal.releaseWay': '发布方式',
    'riskControl.bpmnDetail.agreeModal.releaseWay.gray': '灰度发布',
    'riskControl.bpmnDetail.agreeModal.releaseWay.all': '全量发布',
    // dryrun Modal
    'riskControl.bpmnDetail.dataTestModal.title.instruction': '使用说明',
    'riskControl.bpmnDetail.dataTestModal.process_key': '指定过程 key',
    'riskControl.bpmnDetail.dataTestModal.value': '提币的数量',
    'riskControl.bpmnDetail.dataTestModal.org_id': '机构 ID',
    'riskControl.bpmnDetail.dataTestModal.account_id': '账户 ID',
    'riskControl.bpmnDetail.dataTestModal.user_id': '用户 ID',
    'riskControl.bpmnDetail.dataTestModal.from_wallet_id': '提币发起钱包 ID',
    'riskControl.bpmnDetail.dataTestModal.from_wallet_name': '提币发起钱包名称',
    'riskControl.bpmnDetail.dataTestModal.from_account_email_or_api': '提币发邮箱或 API',
    'riskControl.bpmnDetail.dataTestModal.source': '来源',
    'riskControl.bpmnDetail.dataTestModal.request_id': '请求 ID',
    'riskControl.bpmnDetail.dataTestModal.coin_type': '币种类型',
    'riskControl.bpmnDetail.dataTestModal.to_address': '发往地址',
    'riskControl.bpmnDetail.dataTestModal.fee': '手续费',
    'riskControl.bpmnDetail.dataTestModal.memo': '备忘录',
    'riskControl.bpmnDetail.dataTestModal.to_custody_wallet': '是否是内部交易',
    'riskControl.bpmnDetail.dataTestModal.template_version': '模版版本',
    'riskControl.bpmnDetail.dataTestModal.ip': 'IP',
    'riskControl.bpmnDetail.dataTestModal.phone': '电话号码',
    'riskControl.bpmnDetail.dataTestModal.device_id': '设备 ID',
    'riskControl.bpmnDetail.dataTestModal.user_agent': 'User Agent',

    /**
     * BPMN Business Definition 列表
     */
    // tab title
    'riskControl.businessDefinition.tab.title': 'Process Definition',
    // table colomn
    'riskControl.businessDefinition.list.Table.colomnTitle.id': 'id',
    'riskControl.businessDefinition.list.Table.colomnTitle.processName': 'process_name',
    'riskControl.businessDefinition.list.Table.colomnTitle.processKey': 'process_key',
    'riskControl.businessDefinition.list.Table.colomnTitle.bpmnResourceIds': 'bpmn_resource_ids',
    'riskControl.businessDefinition.list.Table.colomnTitle.subscribeMethod': 'subscribe_method',
    'riskControl.businessDefinition.list.Table.colomnTitle.subscribeQueue': 'subscribe_queue',
    'riskControl.businessDefinition.list.Table.colomnTitle.createdTime': '创建时间',
    'riskControl.businessDefinition.list.Table.colomnTitle.modifiedTime': '修改时间',
    'riskControl.businessDefinition.list.Table.colomnTitle.operatorName': '更新人',
    'riskControl.businessDefinition.list.Table.content.viewName': '查看名称',
    // 请求提示
    'riskControl.businessDefinition.list.request.updateProcessDefinition.success': '更新 process definition 成功',
    'riskControl.businessDefinition.list.request.createProcessDefinition.success': '创建 process definition 成功',
};

export default riskControlRuleManagement;
