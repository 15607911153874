const asynqmon = {
    'tss.asynqmon': 'asynqmon',
    'tss.queues': 'queues',
    'tss.servers': 'servers',
    'tss.redis': 'redis',
    'tss.schedulers': 'schedulers',
    'tss.queuesDetail': 'queues Detail',
    'tss.tasksDetail': 'tasks Detail',

};
export default asynqmon;
