const asynqmon = {
    'tss.asynqmon': '异步任务管理',
    'tss.queues': '队列',
    'tss.servers': '服务器',
    'tss.redis': 'Redis',
    'tss.schedulers': '调度器',
    'tss.queuesDetail': '队列详情',
    'tss.tasksDetail': '任务详情',

};
export default asynqmon;
