const mpckyt = {
    'custody.mpckyt.siderBar.title': 'MPC KYT',
    'custody.mpckytDetail.siderBar.title': 'MPC KYT Detail',
    'custody.mpckyt.search.orgnameOrid': 'Org name/id',
    'custody.mpckyt.search.level': 'Level',
    'custody.mpckyt.search.category': 'Category',
    'custody.mpckyt.search.toAddress': 'To address',
    'custody.mpckyt.search.fromAddress': 'From address',
    'custody.mpckyt.text.exposureType': 'ExposureType',
    'custody.mpckyt.text.amount': 'Amount',
    'custody.mpckyt.text.kycId': 'KYC ID',
    'custody.mpckyt.text.action': 'Result',
    'custody.mpckyt.text.coin': 'Coin',
    'custody.mpckyt.status.freezed': 'Freezed',
    'custody.mpckyt.status.unfreezed': 'Unfreezed',
    'custody.mpckyt.status.pending': 'Pending',
    'custody.mpckyt.status.rejected': 'Rejected',
    'custody.mpckyt.status.apprival': 'Apprival',
};
export default mpckyt;
