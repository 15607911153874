const suspendedManagement = {
    'custody.suspendedManagement.tableTitle.coin': '币种',
    'custody.suspendedManagement.tableTitle.recvSuspended': '充币状态',
    'custody.suspendedManagement.tableTitle.sendSuspended': '提币状态',
    'custody.suspendedManagement.FormItem.suspendedRecv': '暂停充币',
    'custody.suspendedManagement.FormItem.suspendedSend': '暂停提币',
    'custody.suspendedManagement.FormItem.subSuspendedSend': '子币同时生效',
    'custody.suspendedManagement.Button.suspendedSend': '暂停充提',
    'custody.suspendedManagement.modalTitle.suspendedSend': '修改充提信息',
    'custody.suspendedManagement.suspended.open.status': '开启',
    'custody.suspendedManagement.suspended.close.status': '关闭',
};

export default suspendedManagement;
