import { useEffect } from 'react';

const useAddUidToBody = (uid) => {
    useEffect(() => {
        if (!uid) {
            return;
        }
        const base64Uid = btoa(uid);
        document.body.setAttribute('uid', base64Uid);
    }, [uid]);
};

export default useAddUidToBody;
