import { useEffect } from 'react';

/**
 * useTrackingScript - Dynamically load tracking script based on environment and product line.
 *
 * @param {('sandbox' | 'production')} env - The environment identifier to select the appropriate script URL.
 * @param {('Dmp')} productLine - The product line identifier to select the corresponding script. Default is 'Dmp'.
 */
const useTrackingScript = (env, productLine = 'Dmp') => {
    // Define the map for script URLs based on environment and product line
    const scriptMap = {
        sandbox: {
            Dmp: 'https://collect.sandbox.cobo.com/sdk/202309213452.js',
        },
        production: {
            Dmp: 'https://collect.cobo.com/sdk/20230921938.js',
        },
    };

    // Get the script URL based on env and productLine
    const scriptSrc = scriptMap[env]?.[productLine];

    useEffect(() => {
        if (scriptSrc) {
            // Create and append the script tag
            const script = document.createElement('script');
            script.src = scriptSrc;
            script.async = true;

            document.body.appendChild(script);

            // Cleanup function: remove the script when component unmounts
            return () => {
                document.body.removeChild(script);
            };
        }
    }, [scriptSrc]); // Re-run the effect if env or productLine changes
};

export default useTrackingScript;
