const coinManage = {
    'custody.coinManage.siderBar.title': 'Coin Manage',
    'custody.coinManage.table.header.chainCoin': 'Chain coin',
    'custody.coinManage.table.header.fuzzyMatch': 'fuzzy match',
    'custody.coinManage.table.header.precision': 'precision',
    'custody.coinManage.table.header.minimumDepositThreshold': 'minimum deposit amount',
    'custody.coinManage.table.header.visible': 'visible range',
    'custody.coinManage.table.header.displayCode': 'display code',
    'custody.coinManage.table.header.confirmThreshold': 'Confirmation Threshold',
    'custody.coinManage.table.button.edit': 'Edit visible range',
};

export default coinManage;
