const cobo_wallet_page = {
    'crm.cloud_wallet.userSerachDividerTitle': 'User Information',
    'crm.cloud_wallet.searchConditionUserName': 'Mobile or Email',
    'crm.cloud_wallet.searchConditionUserID': 'ID',
    'crm.cloud_wallet.searchConditionUserAccount': 'Account',
    'crm.cloud_wallet.searchConditionUserNationality': 'Nationality',
    'crm.cloud_wallet.searchConditionUserTags': 'Tags',
    'crm.cloud_wallet.searchConditionWithFinancialProduct': 'With Financial Product',
    'crm.cloud_wallet.searchConditionIsVIP': 'Is VIP',
    'crm.cloud_wallet.searchConditionPhone': 'Mobile',
    'crm.cloud_wallet.searchBtn': 'Search',
    'crm.cloud_wallet.clearBtn': 'Clear',
    'crm.cloud_wallet.table.header': 'User Information',
    'crm.cloud_wallet.table.head.index1': 'ID',
    'crm.cloud_wallet.table.head.index2': 'Name',
    'crm.cloud_wallet.table.head.index3': 'Is Active',
    'crm.cloud_wallet.table.head.index4': 'Join time',
    'crm.cloud_wallet.table.head.index5': 'Nationality',
    'crm.cloud_wallet.table.head.index6': 'KYC',
    'crm.cloud_wallet.table.head.index7': '2FA',
    'crm.cloud_wallet.table.head.index8': 'Amount',
    'crm.cloud_wallet.table.head.index9': 'Financial Product',
    'crm.cloud_wallet.table.head.index10': 'Tags',
    'crm.cloud_wallet.table.head.index11': 'Identity',
    'crm.cloud_wallet.table.head.feature_config': 'Feature Whitelist',
    'crm.cloud_wallet.table.head.new_devices': 'New Devices',
    'crm.cloud_wallet.table.head.paymentpassword': 'Payment password',
    'crm.cloud_wallet.table.head.hodor_change_approval': 'Cobo Auth Change',
    'crm.cloud_wallet.feature_config': 'Feature Whitelist',
    'crm.cloud_wallet.securiy_email': 'Security Email',
    'crm.cloud_wallet.new_devices.unlock': 'unlock',
    'crm.cloud_wallet.new_devices.unlocked': 'Unlocked',

    'crm.cloud_wallet.view_kyc_change': 'KYC levels about ',
    'crm.cloud_wallet.change_push': 'Submit',
    'crm.cloud_wallet.change_cancel': 'Cancel',
    'crm.cloud_wallet.2faUnbind': 'Unbind 2FA about user ',
    'crm.cloud_wallet.addCC': 'Add CC ',
    'crm.cloud_wallet.amount.coins_empty': 'No Coins',

    'crm.cloud_wallet.tagsTitle': 'Please input tags about ',
    'crm.cloud_wallet.identityTitle': 'Base Information of user ',
    'crm.cloud_wallet.identity.picture': 'Picture Informations',

    'crm.cloud_wallet.identity.id': 'ID : ',
    'crm.cloud_wallet.identity.tags': 'tags : ',
    'crm.cloud_wallet.identity.name': 'name : ',
    'crm.cloud_wallet.identity.nationality': 'nationality : ',
    'crm.cloud_wallet.identity.account': 'account : ',
    'crm.cloud_wallet.identity.id_card': 'ID card : ',
    'crm.cloud_wallet.identity.kyc': 'KYC : ',
    'crm.cloud_wallet.identity.member_level_title': 'Member Level of user ',
    'crm.cloud_wallet.identity.member_level': 'Member Level : ',
    'crm.cloud_wallet.identity.btn.upload': 'Upload Now',
    'crm.cloud_wallet.identity.not_enough_pictures': 'Not enough pictures',
    'crm.cloud_wallet.identity.upload_desc': 'Click or drag file to this area to upload',
    'crm.cloud_wallet.amountTitle': 'Amount ',
    'crm.cloud_wallet.CC': 'CC',
    'crm.cloud_wallet.normalCoins': 'Normal Coins',

    'crm.cloud_wallet.feature_config.mortgage': 'Mortgage Tab',
    'crm.cloud_wallet.feature_config.rewards': 'Rewards Tab',
    'crm.cloud_wallet.feature_config.triple': 'Triple',
    'crm.cloud_wallet.feature_config.bitgrow': 'BitGrow',
    'crm.cloud_wallet.feature_config.staking': 'Staking',
    'crm.cloud_wallet.feature_config.exchange': 'Exchange',
    'crm.cloud_wallet.feature_config.etf': 'ETF Fund',
    'crm.cloud_wallet.feature_config.dapp': 'Discover Tab',
    'crm.cloud_wallet.feature_config.candy': 'Cobo Candy',
    'crm.cloud_wallet.feature_config.enabled': 'Enabled',
    'crm.cloud_wallet.feature_config.disabled': 'Disabled',

    'crm.cloud_wallet.modalubk': 'Yubikey',
};

export default cobo_wallet_page;
