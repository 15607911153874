const mpcWithdrawExploer = {
    'custody.mpcWithdrawExploer.searchCondition.type': '类型',
    'custody.mpcWithdrawExploer.select.withdrawalType.Withdraw': '地址提币',
    'custody.mpcWithdrawExploer.select.withdrawalType.Contractcall': '合约交互',
    'custody.mpcWithdrawExploer.detail.label.Protocol': '合约名',
    'custody.mpcWithdrawExploer.detail.transaction.status.init': '已创建',
    'custody.mpcWithdrawExploer.detail.transaction.status.toBeSent': '待发送',
    'custody.mpcWithdrawExploer.detail.transaction.status.Rejected': '拒绝',
    'custody.mpcWithdrawExploer.detail.transaction.status.checked': '预检查',
    'custody.mpcWithdrawExploer.detail.transaction.status.Queued': '队列中',
    'custody.mpcWithdrawExploer.detail.transaction.status.waitSign': '等待签名',
    'custody.mpcWithdrawExploer.detail.transaction.status.Pending': 'Pending',
    'custody.mpcWithdrawExploer.detail.transaction.status.Singed': '已签名',
    'custody.mpcWithdrawExploer.detail.transaction.status.Broadcast': '已广播',
    'custody.mpcWithdrawExploer.detail.transaction.status.BroadcastFail': '广播失败',
    'custody.mpcWithdrawExploer.detail.transaction.status.Success': '成功',
    'custody.mpcWithdrawExploer.detail.transaction.status.Failed': '失败',
    'custody.mpcWithdrawExploer.detail.transaction.status.rbf': 'Replace by RBF',
    'custody.mpcWithdrawExploer.detail.transaction.status.Invalid': '无效交易',
};
export default mpcWithdrawExploer;
