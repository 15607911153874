const riskControlMenuEvent = {
    /**
     * 左侧菜单
     */
    'aladdin.risk_menu': '风控名单管理',
    'riskMenu.menuEvent': '名单项目管理',
    'riskMenu.menuEvent.userId': '用户ID管理',
    'riskMenu.menuEvent.orgId': 'Org ID管理',
    'riskMenu.menuEvent.ip': 'IP地址管理',
    'riskMenu.menuEvent.imei': '设备号管理',
    'riskMenu.menuEvent.phone': '手机号管理',
    'riskMenu.menuEvent.pay': '支付账户管理',
    'riskMenu.menuEvent.toAddress': '收款地址管理',
    'riskMenu.menuEvent.individualToAddress': '用户_提币地址管理',
    'riskMenu.menuEvent.userDevice': '用户_设备管理',
    'riskMenu.menuEvent.userIp': '用户_IP管理',
    'riskMenu.menuEvent.orgIp': 'Org_IP管理',
    'riskMenu.menuEvent.orgUA': 'Org_UA管理',
    'riskMenu.menuEvent.email': '邮箱管理',
    'riskMenu.menuEvent.selectAll': '全选',

    /**
     * 名单管理页面
     */
    'riskMenu.menuEvent.menuEventManage.form.createMenuEvent': '新增名单项目',
    'riskMenu.menuEvent.menuEventManage.form.walletType': '钱包类型',
    'riskMenu.menuEvent.menuEventManage.walletType.1': '通用',
    'riskMenu.menuEvent.menuEventManage.walletType.2': '全托管',
    'riskMenu.menuEvent.menuEventManage.walletType.3': 'MPC',

    /**
     * 子名单通用部分
     */
    'riskMenu.submenu.form.menuName': '名单项目名称',
    'riskMenu.submenu.form.menuType': '名单类型',
    'riskMenu.submenu.form.menuStatus': '状态',
    'riskMenu.submenu.form.menuStatus.all': '全部状态',
    'riskMenu.submenu.form.menuStatus.valid': '有效',
    'riskMenu.submenu.form.menuStatus.invalid': '失效',
    'riskMenu.submenu.form.menuDesc': '备注',
    'riskMenu.submenu.form.endTime': '结束时间',
    'riskMenu.submenu.form.modifiedTime': '更新时间',
    'riskMenu.submenu.form.creator': '操作人',
    'riskMenu.submenu.form.textarea.placeholder': '批量添加时请使用逗号隔开',
    'riskMenu.submenu.form.textarea.composite-placeholder': "复合名单使用'_'连接，批量添加时请使用逗号隔开",
    'riskMenu.submenu.form.textarea.composite-error': '内容格式不正确',
    'riskMenu.submenu.form.textarea.error': '内容格式不正确',

    /**
     * 子名单：用户ID
     */
    'riskMenu.submenu.form.userId': '用户ID',
    'riskMenu.submenu.createSubmenuModel.title.userId': '新增用户ID名单',

    /**
     * Organization id 管理页面
     */
    'riskMenu.submenu.form.orgId': 'Org ID',
    'riskMenu.submenu.createSubmenuModel.title.orgId': '新增 Org ID 名单',

    /**
     * IP 地址管理页面
     */
    'riskMenu.submenu.form.ip': 'IP地址',
    'riskMenu.submenu.createSubmenuModel.title.ip': '新增 IP 地址名单',

    /**
     * IMEI 移动设备识别码管理页面
     */
    'riskMenu.submenu.form.imei': '设备号',
    'riskMenu.submenu.createSubmenuModel.title.imei': '新增设备号名单',

    /**
     * 支付账户管理页面
     */
    'riskMenu.submenu.form.pay': '支付账户',
    'riskMenu.submenu.createSubmenuModel.title.pay': '新增支付账户名单',

    /**
     * 手机号管理页面
     */
    'riskMenu.submenu.form.phone': '手机号',
    'riskMenu.submenu.createSubmenuModel.title.phone': '新增手机号名单',

    /**
     * 收款地址管理
     */
    'riskMenu.submenu.form.toAddress': '收款地址',
    'riskMenu.submenu.createSubmenuModel.title.toAddress': '新增收款地址名单',

    /**
     * 用户-提币地址管理
     */
    'riskMenu.submenu.form.individualToAddress': '用户_提币地址',
    'riskMenu.submenu.createSubmenuModel.title.individualToAddress': '新增用户_提币地址名单',

    /**
     * 用户-设备管理
     */
    'riskMenu.submenu.form.userDevice': '用户_设备',
    'riskMenu.submenu.createSubmenuModel.title.userDevice': '新增用户_设备名单',

    /**
     * 用户-IP管理
     */
    'riskMenu.submenu.form.userIp': '用户_IP',
    'riskMenu.submenu.createSubmenuModel.title.userIp': '新增用户_IP名单',

    /**
     * Org_IP管理
     */
    'riskMenu.submenu.form.orgIp': 'Org_IP',
    'riskMenu.submenu.createSubmenuModel.title.orgIp': '新增Org_IP名单',

    /**
     * Org_UA管理
     */
    'riskMenu.submenu.form.orgUA': 'Org_UA',
    'riskMenu.submenu.createSubmenuModel.title.orgUA': '新增Org_UA名单',

    /**
     * 邮箱管理
     */
    'riskMenu.submenu.form.email': '邮箱',
    'riskMenu.submenu.createSubmenuModel.title.email': '新增邮箱名单',
};

export default riskControlMenuEvent;
