const travelRuleInboundRefund = {
    'custody.travelRuleInboundRefund.title': 'Travel Rule 入账退款',
    'custody.travelRuleInboundRefund.siderBar.title': 'Travel Rule 入账退款',

    'custody.travelRuleInboundRefund.label.txid': '交易ID',
    'custody.travelRuleInboundRefund.label.txaddrfrom': '来源地址',
    'custody.travelRuleInboundRefund.label.txaddrto': '目标地址',
    'custody.travelRuleInboundRefund.label.orgidorname': '机构名称或ID',
    'custody.travelRuleInboundRefund.label.coin': '币种',
    'custody.travelRuleInboundRefund.label.status': '状态',

    'custody.travelRuleInboundRefund.placeholder.txid': '交易ID',
    'custody.travelRuleInboundRefund.placeholder.txaddrfrom': '来源地址',
    'custody.travelRuleInboundRefund.placeholder.txaddrto': '目标地址',
    'custody.travelRuleInboundRefund.placeholder.orgidorname': '机构名称或ID',
    'custody.travelRuleInboundRefund.placeholder.coin': '币种',
    'custody.travelRuleInboundRefund.placeholder.status': '状态',

    'custody.travelRuleInboundRefund.searchBtn.desc': '搜索',
    'custody.travelRuleInboundRefund.resetBtn.desc': '重置',

    'custody.travelRuleInboundRefund.table.org_id': '机构ID',
    'custody.travelRuleInboundRefund.table.org_name': '机构名称',
    'custody.travelRuleInboundRefund.table.coin': '币种',
    'custody.travelRuleInboundRefund.table.amount': '金额',
    'custody.travelRuleInboundRefund.table.timestamp': '交易时间',
    'custody.travelRuleInboundRefund.table.txid': '交易ID',
    'custody.travelRuleInboundRefund.table.txaddrfrom': '发送地址',
    'custody.travelRuleInboundRefund.table.txaddrto': '接收地址',
    'custody.travelRuleInboundRefund.table.status': '状态',
    'custody.travelRuleInboundRefund.table.actions': '操作',

    'custody.travelRuleInboundRefund.table.actions.refund': '退回',
    'custody.travelRuleInboundRefund.confirm.refund.title': '退回资金',
    'custody.travelRuleInboundRefund.confirm.refund.desc': '您确定要退回该笔资金吗吗？',
    'custody.travelRuleInboundRefund.confirm.refund.cancelled': '已取消退回资金操作',
    'custody.travelRuleInboundRefund.table.actions.pass': '强制通过',
    'custody.travelRuleInboundRefund.confirm.pass.title': '强制通过 Travel Rule 交易',
    'custody.travelRuleInboundRefund.confirm.pass.desc': '您确定要跳过 Travel Rule 合规，强制通过该交易吗？',
    'custody.travelRuleInboundRefund.confirm.pass.cancelled': '已取消强制通过 Travel Rule 操作',
    'custody.travelRuleInboundRefund.table.actions.refund.message': '请在您的 Cobo Guard 上继续操作',
    'custody.travelRuleInboundRefund.table.actions.pass.message': '请在您的 Cobo Guard 上继续操作',
    'custody.travelRuleInboundRefund.table.actions.not-operable.message': '此记录由于以下原因，暂不能操作：{reason}',
    'custody.travelRuleInboundRefund.table.actions.not-operable.default-reason': '未指明的原因',
};
export default travelRuleInboundRefund;
