const superloopExchange = {
    'custody.superloopExchange.siderBar.title': 'superloopExchange',
    'custody.superloopExchange.siderBar.detail.title': 'superloopExchange Detail',
    'custody.superloopExchange.table.header.coin': 'Coin',
    'custody.superloopExchange.table.header.amount': 'Amount',
    'custody.superloopExchange.table.header.fiatCurrency': 'fiat currency',
    'custody.superloopExchange.table.header.status': 'Status',

    'custody.superloopExchange.detail.exchange': 'Exchange',
    'custody.superloopExchange.detail.frozenWalletName': 'Frozen Wallet Name',
    'custody.superloopExchange.detail.walletType': 'Wallet type',
    'custody.superloopExchange.detail.freezeAddress': 'Freeze address',
    'custody.superloopExchange.detail.process.userApplication': 'User Application',
    'custody.superloopExchange.detail.process.exchangeProcessing': 'Exchange Processing',
    'custody.superloopExchange.detail.process.exchangeConfirm': 'Exchange confirming',
    'custody.superloopExchange.detail.process.exchangeRejected': 'Exchange rejected at',
    'custody.superloopExchange.detail.process.exchangeApproval': 'Exchange approval at',
    'custody.superloopExchange.detail.process.waitinUserAuth': 'Waitin user auth',
    'custody.superloopExchange.detail.process.userAuthApproval': 'User auth approval',

    'custody.superloopExchange.table.header.status.init': 'init',
    'custody.superloopExchange.table.header.status.Active': 'Active',
    'custody.superloopExchange.table.header.status.Terminated': 'Terminated',

    'custody.superloop.table.header.status.ExchangePreCreateCreditProcessing': 'Exchange Pre Create Credit Processing ',
    'custody.superloop.table.header.status.ExchangePreCreateCreditUnkownError':
        'Exchange Pre Create Credit Unkown Error',
    'custody.superloop.table.header.status.LockProcessing': 'Lock Processing',
    'custody.superloop.table.header.status.LockUnknownError': 'Lock Unknown Error',
    'custody.superloop.table.header.status.ExchangeConfirmCreateCreditProcessing':
        'Exchange Confirm Create Credit Processing',
    'custody.superloop.table.header.status.ExchangeConfirmCreateCreditUnknownError':
        'Exchange Confirm Create Credit Unknown Error',
    'custody.superloop.table.header.status.ExchangeCancelCreateCreditProcessing':
        'Exchange Cancel Create Credit Processing',
    'custody.superloop.table.header.status.ExchangeCancelCreateCreditUnknownError':
        'Exchange Cancel Create Credit Unknown Error',
    'custody.superloop.table.header.status.CreateCanceledByAuth': 'Create Canceled By Auth',
    'custody.superloop.table.header.status.CreateCanceledByExchange': 'Create Canceled By Exchange',
    'custody.superloop.table.header.status.CreateCanceledByLockFail': 'Create Canceled By Lock Fail',
    'custody.superloop.table.header.status.FinalSettleProcessing': 'Final Settle Processing',
    'custody.superloop.table.header.status.FinalSettleUnknownError': 'Final Settle Unknown Error',
    'custody.superloop.table.header.status.FinalSettleCompleted': 'Final Settle Completed',

    'custody.superloop.detail.settleStatus.init': 'created',

    'custody.superloop.detail.settleStatus.Success': 'Success',
    'custody.superloop.detail.settleStatus.Failed': 'Failed',
    'custody.superloop.detail.settleStatus.PrepareFromCoboProcessing': 'Prepare From Cobo Processing',
    'custody.superloop.detail.settleStatus.PrepareFromCoboProcessingUnknownError':
        'Prepare From Cobo Processing Unknown Error',
    'custody.superloop.detail.settleStatus.TransferFromCoboProcessing': 'Transfer From Cobo Processing',
    'custody.superloop.detail.settleStatus.TransferFromCoboProcessingUnknownError':
        'Transfer From Cobo Processing Unknown Error',
    'custody.superloop.detail.settleStatus.MonitorToCoboProcessing': 'Monitor To Cobo Processing',
    'custody.superloop.detail.settleStatus.MonitorToCoboUnknownError': 'Monitor To Cobo Unknown Error',
    // 'custody.superloop.detail.settleStatus.': '',
};
export default superloopExchange;
