const botInstances = {
    'argus.bot_instances': 'Bot Instances',
    'aladdin.argus': 'Argus',
    'argus.bots': 'Argus Bots',
    'argus.safe.address': 'Safe Address',
    'argus.bot.status': 'Status',
    'argus.bot.type': 'Bot Type',
    'argus.bot.type.customized': 'Customized', // 3
    'argus.bot.type.farming': 'Farming', //4
    'argus.bot.type.withdraw': 'Withdraw', //5
    'argus.bot.type.deleverage': 'Deleverage', // 6
    'argus.bot.type.leverage': 'Leverage', // 7
    'argus.all': 'All',
    'argus.bot.running': 'Running', // 1
    'argus.bot.paused': 'Paused', // 2
    'argus.bot.terminated': 'Terminated', // 3
    'argus.safe_address': 'Safe Address',
    'argus.safe_name': 'Safe Name',
    'argus.bot_address': 'Bot Address',
    'argus.bot_instance_name': 'Bot Instance Name',
    'argus.bot_gas_balance': 'Gas Balance',
    'argus.chain': 'Chain',
    'argus.org_name': 'Org',
    'argus.bot.instance_id': 'Bot id',
    'argus.bot.triggers': 'Trigger',
    'argus.bot.last_execution_result': 'Last Execution Result',
    'argus.bot.last_execution_time': 'Last Execution Time',
    'argus.bot.last_success_execution_time': 'Last Success Execution Time',
    'argus.bot.last_success_execution_tx': 'Last Success Execution Tx',
    'argus.bot.next_execution_time': 'Next Execution Time',
    'argus.bot_definition_name': 'Bot Definition Name',
    'argus.bot_config': 'Config',
};
export default botInstances;
