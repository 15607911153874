const approvalSystem = {
    'approvalSystem.createApprovalProcess': 'create Approval Process',
    'approvalSystem.approvalProcessDetail': 'Approval Process Detail',
    'approvalSystem.approvalManage': 'approval Manage',
    'approvalSystem.approvalDetail': 'approval Detail',
    'approvalSystem.createApproval': 'create approval',
    'approvalSystem.initApproval': 'init Approval',
    'approvalSystem.approvalCenter': 'approval Center',
};
export default approvalSystem;
