import zhCN from './langConfig/zh-CN';
import enUS from './langConfig/en-US/index';
import { IntlProvider } from 'react-intl';
import enUSAnt from 'antd/es/locale/en_US';
import zhCNAnt from 'antd/es/locale/zh_CN';
import Layouts from './components/layouts';
import { createHashHistory } from 'history';
import GoogleLoginComponentObj from './components/google_login';
import { HashRouter, Switch, Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { ConfigProvider } from 'antd';
import { StyleProvider } from '@ant-design/cssinjs';
import { Watermark, App } from 'antd';
import useTrackingScript from '@/hooks/useTrackingScript';
import useAddUidToBody from '@/hooks/useAddUidToBody';

const langMap = {
    zh: zhCN,
    en: enUS,
};
const langAntMap = {
    zh: zhCNAnt,
    en: enUSAnt,
};

function Appw(props) {
    const { locale, userInfo } = props;
    useTrackingScript(process.env.REACT_APP_ENVIRONMENT);
    useAddUidToBody(userInfo.email || '');
    return (
        <App>
            <Watermark content={userInfo.email} gap={[60, 60]} font={{ color: 'rgba(0,0,0,.06)' }} zIndex='1001'>
                <ConfigProvider
                    locale={langAntMap[locale]}
                    theme={{
                        token: {
                            colorPrimary: '#3377FF',
                        },
                        components: {
                            Select: {
                                controlItemBgActive: 'transparent',
                            },
                        },
                    }}
                >
                    <StyleProvider hashPriority='high'>
                        <IntlProvider locale={locale} messages={langMap[locale]}>
                            <HashRouter history={createHashHistory}>
                                <Switch>
                                    <Route
                                        path='/login'
                                        render={() =>
                                            window.localStorage.aladdin_session ? (
                                                <Redirect to={{ pathname: '/' }} />
                                            ) : (
                                                <GoogleLoginComponentObj />
                                            )
                                        }
                                    />
                                    <Route
                                        path='/'
                                        render={() =>
                                            window.localStorage.aladdin_session ? (
                                                <Layouts />
                                            ) : (
                                                <Redirect to={{ pathname: '/login' }} />
                                            )
                                        }
                                    />
                                    <Redirect path='/' to={{ pathname: '/login' }} />
                                </Switch>
                            </HashRouter>
                        </IntlProvider>
                    </StyleProvider>
                </ConfigProvider>
            </Watermark>
        </App>
    );
}
const mapStateToProps = (state) => ({
    locale: state.Language.locale,
    locale_desc: state.Language.locale_desc,
    userInfo: state.common.userInfo,
});

export default connect(mapStateToProps)(Appw);
