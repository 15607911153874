const manualInbound = {
    'blockchain.manualInbound': 'Refund Supplementary Entry',
    'blockchain.manualInbound.table.head.txid': 'RefundsTxid',

    'blockchain.manualInbound.table.head.withdraw_txid': 'Txid',

    'blockchain.manualInbound.table.head.orgId': 'Org ID',
    'blockchain.manualInbound.table.head.orgName': 'Org name',
    'blockchain.manualInbound.table.head.Result': 'Result',

    'blockchain.manualInbound.formItem.label.refundsAddress': 'Refunds Address',
    'blockchain.manualInbound.formItem.label.inboundAddress': 'Inbound Address',
    'blockchain.manualInbound.formItem.label.inboundAddressMemo': 'Inbound Address memo',
    'blockchain.manualInbound.formItem.label.amount': 'Amount',
    'blockchain.manualInbound.formItem.label.phaTaskId': 'Phabricator Task',
    'blockchain.manualInbound.formItem.label.note': 'Note',
    'blockchain.manualInbound.formItem.label.chainCoin': 'Chain_coin',
};
export default manualInbound;
